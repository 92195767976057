<form
  (ngSubmit)="formCompanyService.submitForm()"
  *ngIf="formCompanyService.formGroup"
  [formGroup]="formCompanyService.formGroup"
>
  <ng-container *ngIf="formCompanyService.formGroupChanges$ | async"></ng-container>
  <div class="settings-card__content">
    <dougs-company-informations [company]="company"></dougs-company-informations>
    <div>
      <dougs-company-global
        *ngIf="company && companyFields"
        [companyFields]="companyFields"
        [company]="company"
        [dougsDetectFormChanges]="formCompanyService.formGroupHasBeenTouched$ | async"
      ></dougs-company-global>
      <dougs-loader *ngIf="!company || !companyFields"></dougs-loader>
    </div>
  </div>
  <dougs-divider></dougs-divider>
  <dougs-company-legal [companyFields]="companyFields" [company]="company"></dougs-company-legal>
  <dougs-divider></dougs-divider>
  <dougs-company-headquarter [companyFields]="companyFields" [company]="company"></dougs-company-headquarter>
  <ng-container *ngIf="companyFields && companyFields['metadata.courtsAdministrationService']?.isAvailable">
    <dougs-divider></dougs-divider>
    <dougs-company-court-administration
      [companyFields]="companyFields"
      [company]="company"
    ></dougs-company-court-administration>
  </ng-container>
  <ng-container
    *ngIf="
      (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
      companyFields &&
      companyFields['metadata.companyTaxesService']?.isAvailable
    "
  >
    <dougs-divider></dougs-divider>
    <dougs-company-taxes-service [companyFields]="companyFields" [company]="company"></dougs-company-taxes-service>
  </ng-container>

  <div *ngIf="formCompanyService.formGroupHasBeenDirty" class="settings-card__button-container py-16 px-32">
    <dougs-button (click)="formCompanyService.resetForm(company)" class="mr-24" color="secondary">Annuler</dougs-button>
    <dougs-button type="submit">Enregistrer</dougs-button>
  </div>
</form>
