import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ButtonComponent, DetectFormChangesDirective, DividerComponent, LoaderComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyService } from '../../forms/form-company.service';
import { CompanyCourtAdministrationComponent } from './company-court-administration/company-court-administration.component';
import { CompanyGlobalComponent } from './company-global/company-global.component';
import { CompanyHeadquarterComponent } from './company-headquarter/company-headquarter.component';
import { CompanyInformationsComponent } from './company-informations/company-informations.component';
import { CompanyLegalComponent } from './company-legal/company-legal.component';
import { CompanyTaxesServiceComponent } from './company-taxes-service/company-taxes-service.component';

@Component({
  selector: 'dougs-company',
  templateUrl: './company.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CompanyInformationsComponent,
    CompanyGlobalComponent,
    DetectFormChangesDirective,
    LoaderComponent,
    DividerComponent,
    CompanyLegalComponent,
    CompanyHeadquarterComponent,
    CompanyCourtAdministrationComponent,
    CompanyTaxesServiceComponent,
    ButtonComponent,
    AsyncPipe,
  ],
  providers: [FormCompanyService],
})
export class CompanyComponent {
  private _company!: Company;

  @Input()
  set company(company) {
    this._company = company;
    if (this.company) {
      this.formCompanyService.populateForm(this.company);
      this.setDefaultValuesToCustomFields();
    }
  }

  get company(): Company {
    return this._company;
  }

  private _companyFields!: Fields;
  @Input()
  set companyFields(companyFields) {
    this._companyFields = companyFields;
    if (this.companyFields) {
      this.formCompanyService.formatForm(this.companyFields);
    }
  }

  get companyFields(): Fields {
    return this._companyFields;
  }

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly userStateService: UserStateService,
  ) {}

  private setDefaultValuesToCustomFields(): void {
    this.formCompanyService.selectedCourtAdministration = this.company?.metadata?.courtsAdministrationService;
    this.formCompanyService.selectedTaxesService = this.company?.metadata?.companyTaxesService;
    this.formCompanyService.selectedCertifiedOrganism = this.company?.metadata?.certifiedOrganism;
  }
}
