import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Establishment } from '@dougs/company/dto';
import { AddressAutocompleteComponent, ButtonComponent, CheckboxComponent, DividerComponent } from '@dougs/ds';
import { AllFields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FieldComponent } from '@dougs/fields/ui';
import { EstablishmentsStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormEstablishmentsService } from '../../../../../../forms/form-establishments.service';
import { EstablishmentComponentService } from '../../../../../../services/establishments/establishment.component.service';

@Component({
  selector: 'dougs-company-establishment-form',
  templateUrl: './company-establishment-form.component.html',
  styleUrls: ['./company-establishment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FieldComponent,
    CheckboxComponent,
    ButtonComponent,
    AsyncPipe,
    AddressAutocompleteComponent,
    DividerComponent,
  ],
})
export class CompanyEstablishmentFormComponent {
  private _establishmentFields!: AllFields;
  @Input()
  set establishmentFields(establishmentFields: AllFields) {
    this._establishmentFields = establishmentFields;
    if (this.establishmentFields) {
      this.formEstablishmentsService.formatForm(establishmentFields);
    }
  }

  get establishmentFields(): AllFields {
    return this._establishmentFields;
  }

  private _establishment!: Establishment;
  @Input()
  set establishment(establishment) {
    if (establishment) {
      this._establishment = establishment;
      this.formEstablishmentsService.populateForm(establishment);
    }
  }

  get establishment(): Establishment {
    return this._establishment;
  }

  constructor(
    public readonly formEstablishmentsService: FormEstablishmentsService,
    public readonly userStateService: UserStateService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    public readonly fieldsStateService: FieldsStateService,
    public readonly establishmentComponentService: EstablishmentComponentService,
  ) {}
}
