import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfigBackService } from '@dougs/core/config-back';
import { MetricsService } from '@dougs/core/metrics';
import {
  AvatarMessageComponent,
  BlankStateComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { EstablishmentsStateService } from '@dougs/settings/shared';

@Component({
  selector: 'add-secondary-establishment',
  templateUrl: './add-secondary-establishment-modal.component.html',
  styleUrls: ['./add-secondary-establishment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AvatarMessageComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    PanelInfoComponent,
    ModalFooterDirective,
    BlankStateComponent,
  ],
})
export class AddSecondaryEstablishmentModal {
  requestSent = false;
  needHelpForCreateEstablishement: boolean | null = null;
  form: FormGroup = new FormGroup({
    street: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
  });
  private _companyId!: number;

  get street(): AbstractControl | null {
    return this.form.get('street');
  }
  get zipcode(): AbstractControl | null {
    return this.form.get('zipcode');
  }
  get city(): AbstractControl | null {
    return this.form.get('city');
  }

  @Input()
  set companyId(companyId) {
    this._companyId = companyId;
  }

  get companyId(): number {
    return this._companyId;
  }
  constructor(
    @Inject(MODAL_DATA)
    public data: { companyId: number },
    private readonly modalRef: ModalRef,
    public readonly configBackService: ConfigBackService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    private readonly fb: FormBuilder,
    private readonly metricsService: MetricsService,
  ) {
    this.metricsService.pushMixpanelEvent('Accounting Creation Secondary Establishment Modal Viewed');
  }

  async onSubmit(): Promise<void> {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.requestSent = true;
      await this.establishmentsStateService.createSecondaryEstablishmentTask(this.data.companyId, this.form.value);
    }
  }

  trackByValue(index: number, item: { value: number; label: string }): number {
    return item.value;
  }
}
