import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { lastValueFrom, map, take } from 'rxjs';
import { Company, CompanyLegalForm } from '@dougs/company/dto';
import {
  AvatarComponent,
  ButtonComponent,
  ModalService,
  OverlayCloseEvent,
  PartnerImageUrlPipe,
  PillComponent,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { AddAssociateModalComponent } from '../../../../modals/add-associate-modal/add-associate-modal.component';
import { AssociatePillPercentagePipe } from '../../../../pipes/associate-pill-percentage.pipe';
import { PropertyTypePipe } from '../../../../pipes/property-type.pipe';

@Component({
  selector: 'dougs-associate-list',
  templateUrl: './associate-list.component.html',
  styleUrls: ['./associate-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    AvatarComponent,
    NgIf,
    PillComponent,
    NgClass,
    ButtonComponent,
    AsyncPipe,
    AssociatePillPercentagePipe,
    PartnerImageUrlPipe,
    PropertyTypePipe,
  ],
})
export class AssociateListComponent {
  @Input() company!: Company;
  @Input() totalShares!: number;
  @Input() legalForm!: CompanyLegalForm;
  @Input() inCreationModule = false;

  currentAssociates$ = this.partnerStateService.associatePartners$.pipe(
    map((associates) => {
      const currentAssociates: Partner[] = associates.filter((associate) =>
        associate.positions?.some(
          (position) => position.isActive && !position.isEnded && position.key === 'isAssociate',
        ),
      );

      return currentAssociates.sort((a, b) => b.metadata?.associate?.shareCount - a.metadata?.associate?.shareCount);
    }),
  );

  constructor(
    public readonly partnerStateService: PartnerStateService,
    private readonly modalService: ModalService,
  ) {}

  async openAssociateModal(partnerId: string | number, companyId: number): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { PartnerModalComponent } = await import('@dougs/partners/ui');
    this.modalService.open(PartnerModalComponent, {
      data: {
        partnerId,
        companyId,
        sectionId: 'rolesSection',
        containerToOpen: 'isAssociate',
      },
      disableClose: true,
    });
  }

  async openAddPartnerModal(companyId: number): Promise<void> {
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService
        .open(AddAssociateModalComponent, {
          data: {
            companyId,
          },
        })
        .afterClosed$.pipe(take(1)),
    );
    const closeModal = !!modalResult?.data;
    if (closeModal) {
      const createdPartner: Partner = modalResult.data as Partner;
      if (createdPartner) {
        await this.openAssociateModal(createdPartner.id, createdPartner.companyId);
      }
    }
  }

  trackById(index: number, partner: Partner): number {
    return partner.id;
  }
}
