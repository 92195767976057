<div class="settings-card__content">
  <div class="settings-card__sticky-column-title">
    <h5 class="mb-8">Informations juridiques</h5>
  </div>
  <div [formGroup]="formCompanyService.formGroup">
    <ng-container *ngIf="company && companyFields">
      <div
        *ngIf="companyFields['metadata.hasCertifiedOrganism']?.isAvailable"
        class="form-layout mb-16"
        formGroupName="metadata"
      >
        <dougs-field
          [field]="companyFields['metadata.hasCertifiedOrganism']"
          formControlName="hasCertifiedOrganism"
        ></dougs-field>
      </div>
      <ng-container *ngIf="companyFields['metadata.certifiedOrganism.code']?.isAvailable" formGroupName="metadata">
        <div class="form-layout mb-16">
          <dougs-divider></dougs-divider>
        </div>
        <ng-container formGroupName="certifiedOrganism">
          <div *ngIf="companyFields['metadata.certifiedOrganism.code']?.isAvailable" class="form-layout mb-16">
            <div>
              <label dougsFormFieldLabel>Organisme certifié</label>
              <dougs-select
                (ngModelChange)="formCompanyService.updateCertifiedOrganism($event)"
                [disabled]="!companyFields['metadata.certifiedOrganism.code']?.isEditable"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="formCompanyService.selectedCertifiedOrganism"
                bindValue="code"
                dougsFormFieldControl
                maxHeight="250"
                placeholder="-- Organisme certifié --"
              >
                <dougs-select-option [value]="{ code: 'none' }">Aucun</dougs-select-option>
                <dougs-select-option [value]="{ code: 'other' }">Autre</dougs-select-option>
                <dougs-select-option
                  *ngFor="let option of certifiedOrganism$ | async; trackBy: trackByCode"
                  [value]="option"
                >
                  {{ option.name }}
                </dougs-select-option>
              </dougs-select>
            </div>
          </div>
          <div
            *ngIf="companyFields['metadata.certifiedOrganism.subscriptionNumber']?.isAvailable"
            class="form-layout mb-16"
          >
            <dougs-field
              [field]="companyFields['metadata.certifiedOrganism.subscriptionNumber']"
              formControlName="subscriptionNumber"
            ></dougs-field>
          </div>
          <div *ngIf="companyFields['metadata.certifiedOrganism.name']?.isAvailable" class="form-layout mb-16">
            <dougs-field
              [field]="companyFields['metadata.certifiedOrganism.name']"
              formControlName="name"
            ></dougs-field>
          </div>
          <div *ngIf="companyFields['metadata.certifiedOrganism.street']?.isAvailable" class="form-layout mb-16">
            <dougs-field
              [field]="companyFields['metadata.certifiedOrganism.street']"
              formControlName="street"
            ></dougs-field>
          </div>
          <div
            *ngIf="
              companyFields['metadata.certifiedOrganism.zipcode']?.isAvailable ||
              companyFields['metadata.certifiedOrganism.city']?.isAvailable
            "
            class="form-layout mb-16"
          >
            <div class="form-layout__two-columns">
              <dougs-field
                [field]="companyFields['metadata.certifiedOrganism.zipcode']"
                formControlName="zipcode"
              ></dougs-field>
              <dougs-field
                [field]="companyFields['metadata.certifiedOrganism.city']"
                formControlName="city"
              ></dougs-field>
            </div>
          </div>
          <div
            *ngIf="companyFields['metadata.certifiedOrganism.agreementNumber']?.isAvailable"
            class="form-layout mb-16"
          >
            <dougs-field
              [field]="companyFields['metadata.certifiedOrganism.agreementNumber']"
              formControlName="agreementNumber"
            ></dougs-field>
          </div>
          <div
            *ngIf="companyFields['metadata.certifiedOrganism.subscriptionDate']?.isAvailable"
            class="form-layout mb-16"
          >
            <dougs-field
              [field]="companyFields['metadata.certifiedOrganism.subscriptionDate']"
              formControlName="subscriptionDate"
            ></dougs-field>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="companyFields['taxRegimeOptionLetter']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'taxRegimeOptionLetter',
              companyFields['taxRegimeOptionLetter']?.input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['taxRegimeOptionLetter'].input?.fileType,
              'taxRegimeOptionLetter',
              $event
            )
          "
          [field]="companyFields['taxRegimeOptionLetter']"
          formControlName="taxRegimeOptionLetter"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['siren']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (actionButtonClick)="companyComponentService.refreshCompanyFromSiren(company)"
          [actionButtonIcon]="
            companyComponentService.isRefreshingCompanyFromSiren ? 'fal fa-sync fa-spin' : 'fal fa-sync'
          "
          [field]="companyFields['siren']"
          [showActionButton]="company.isCreated && (companyComponentService.shouldShowRefreshCompanyButton$ | async)"
          formControlName="siren"
          [canCopy]="true"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['headquarter.ape']?.isAvailable" class="form-layout mb-16">
        <ng-container formGroupName="headquarter">
          <dougs-field
            [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
            [field]="companyFields['headquarter.ape']"
            formControlName="ape"
          ></dougs-field>
        </ng-container>
      </div>
      <div
        *ngIf="companyFields['headquarter.apeDescription']?.isAvailable"
        class="form-layout mb-16"
        formGroupName="headquarter"
      >
        <dougs-field
          [field]="companyFields['headquarter.apeDescription']"
          formControlName="apeDescription"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['inseePublication']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'inseePublication',
              companyFields['inseePublication'].input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['inseePublication'].input?.fileType,
              'inseePublication',
              $event
            )
          "
          [field]="companyFields['inseePublication']"
          formControlName="inseePublication"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['assetsAllocation']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'assetsAllocation',
              companyFields['assetsAllocation'].input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['assetsAllocation'].input?.fileType,
              'assetsAllocation',
              $event
            )
          "
          [field]="companyFields['assetsAllocation']"
          formControlName="assetsAllocation"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['shareCapital']?.isAvailable" class="form-layout mb-16">
        <dougs-field [field]="companyFields['shareCapital']" formControlName="shareCapital"></dougs-field>
      </div>
      <div *ngIf="companyFields['totalShares']?.isAvailable" class="form-layout form-layout__total-shares mb-16">
        <dougs-field [field]="companyFields['totalShares']" formControlName="totalShares"></dougs-field>
        <dougs-associate-list
          *ngIf="company.legalFormConfig.allowAssociateAccount"
          [company]="company"
          [totalShares]="formCompanyService.formGroup.get('totalShares').value"
          [legalForm]="formCompanyService.formGroup.get('legalForm').value"
        ></dougs-associate-list>
      </div>
      <div
        *ngIf="companyFields['metadata.bankNameOfFundsSubmission']?.isAvailable"
        class="form-layout mb-16"
        formGroupName="metadata"
      >
        <dougs-field
          [field]="companyFields['metadata.bankNameOfFundsSubmission']"
          formControlName="bankNameOfFundsSubmission"
        ></dougs-field>
      </div>
      <div
        *ngIf="companyFields['metadata.fundsSubmissionDate']?.isAvailable"
        class="form-layout mb-16"
        formGroupName="metadata"
      >
        <dougs-field
          [field]="companyFields['metadata.fundsSubmissionDate']"
          formControlName="fundsSubmissionDate"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['certificateOfFundsSubmission']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'certificateOfFundsSubmission',
              companyFields['certificateOfFundsSubmission'].input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['certificateOfFundsSubmission'].input?.fileType,
              'certificateOfFundsSubmission',
              $event
            )
          "
          [field]="companyFields['certificateOfFundsSubmission']"
          formControlName="certificateOfFundsSubmission"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['metadata.isAmbulant']?.isAvailable" class="form-layout mb-16" formGroupName="metadata">
        <dougs-field [field]="companyFields['metadata.isAmbulant']" formControlName="isAmbulant"></dougs-field>
      </div>
      <div *ngIf="companyFields['kbis']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
          (deleteFile)="
            fileStateService.deleteFiles(company, $event, 'kbis', companyFields['kbis']?.input?.allowMultiple)
          "
          (uploadFile)="fileStateService.uploadFiles(company, companyFields['kbis'].input?.fileType, 'kbis', $event)"
          [field]="companyFields['kbis']"
          formControlName="kbis"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['inpiStatement']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'inpiStatement',
              companyFields['inpiStatement']?.input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['inpiStatement'].input?.fileType,
              'inpiStatement',
              $event
            )
          "
          [field]="companyFields['inpiStatement']"
          formControlName="inpiStatement"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['bylaws']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(company, $event, 'bylaws', companyFields['bylaws']?.input?.allowMultiple)
          "
          (uploadFile)="
            fileStateService.uploadFiles(company, companyFields['bylaws'].input?.fileType, 'bylaws', $event)
          "
          [field]="companyFields['bylaws']"
          formControlName="bylaws"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['certificateOfLegalAnnouncementPublication']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'certificateOfLegalAnnouncementPublication',
              companyFields['certificateOfLegalAnnouncementPublication'].input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['certificateOfLegalAnnouncementPublication'].input?.fileType,
              'certificateOfLegalAnnouncementPublication',
              $event
            )
          "
          [field]="companyFields['certificateOfLegalAnnouncementPublication']"
          formControlName="certificateOfLegalAnnouncementPublication"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['subscribers']?.isAvailable" class="form-layout mb-16">
        <dougs-field
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'subscribers',
              companyFields['subscribers'].input?.allowMultiple
            )
          "
          (uploadFile)="
            fileStateService.uploadFiles(company, companyFields['subscribers'].input?.fileType, 'subscribers', $event)
          "
          [field]="companyFields['subscribers']"
          formControlName="subscribers"
        ></dougs-field>
      </div>
      <div
        *ngIf="companyFields['metadata.firstClosingDate']?.isAvailable"
        class="form-layout mb-16"
        formGroupName="metadata"
      >
        <dougs-field
          [field]="companyFields['metadata.firstClosingDate']"
          formControlName="firstClosingDate"
        ></dougs-field>
      </div>
      <div *ngIf="companyFields['activityStartsAt']?.isAvailable" class="form-layout mb-16">
        <dougs-field [field]="companyFields['activityStartsAt']" formControlName="activityStartsAt"></dougs-field>
      </div>
      <div *ngIf="companyFields['metadata.statusSignedAt']?.isAvailable" class="form-layout mb-16">
        <ng-container formGroupName="metadata">
          <dougs-field
            [field]="companyFields['metadata.statusSignedAt']"
            formControlName="statusSignedAt"
          ></dougs-field>
        </ng-container>
      </div>
      <div
        class="settings-card__content__two-columns form-layout mb-16"
        *ngIf="
          companyFields['metadata.dateOfLegalPublication']?.isAvailable ||
          companyFields['metadata.legalPublicationNewspaper']?.isAvailable
        "
      >
        <div *ngIf="companyFields['metadata.dateOfLegalPublication']?.isAvailable" formGroupName="metadata">
          <dougs-field
            [field]="companyFields['metadata.dateOfLegalPublication']"
            formControlName="dateOfLegalPublication"
          ></dougs-field>
        </div>
        <div *ngIf="companyFields['metadata.legalPublicationNewspaper']?.isAvailable" formGroupName="metadata">
          <dougs-field
            [field]="companyFields['metadata.legalPublicationNewspaper']"
            formControlName="legalPublicationNewspaper"
          ></dougs-field>
        </div>
      </div>
      <div *ngIf="companyFields['registrationDate']?.isAvailable" class="form-layout mb-16">
        <dougs-field [field]="companyFields['registrationDate']" formControlName="registrationDate"></dougs-field>
      </div>
      <div *ngIf="companyFields['lockDate']?.isAvailable" class="form-layout mb-16">
        <dougs-field [field]="companyFields['lockDate']" formControlName="lockDate"></dougs-field>
      </div>
    </ng-container>
    <dougs-loader *ngIf="!company || !companyFields"></dougs-loader>
  </div>
</div>
