import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class CompanySummaryHttpService {
  constructor(private readonly http: HttpClient) {}

  getCompanySummary(accountingYear: AccountingYear, isAccountant = false): Observable<any> {
    return this.http.get(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/company-summary`,
      { params: new HttpParams().append('isAccountant', isAccountant) },
    );
  }
}
