<div class="settings-card__content admin">
  <div class="settings-card__sticky-column-title">
    <h5 class="mb-16 color-admin">Service des Impôts des Entreprises</h5>
  </div>
  <div [formGroup]="formCompanyService.formGroup">
    <ng-container formGroupName="metadata">
      <ng-container formGroupName="companyTaxesService">
        <div class="form-layout mb-16">
          <div>
            <label dougsFormFieldLabel>SIE compétent</label>
            <dougs-select
              dougsFormFieldControl
              bindValue="name"
              [searchable]="true"
              maxHeight="300"
              [ngModel]="formCompanyService.selectedTaxesService"
              (ngModelChange)="formCompanyService.updateCompanyTaxesService($event)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!companyFields['metadata.companyTaxesService.name']?.isEditable"
              placeholder="-- SIE compétent --"
            >
              <dougs-select-option
                *ngFor="let option of configBackService.companyTaxesServices$ | async; trackBy: trackByName"
                [value]="option"
              >
                {{ option.name }} ({{ option.zipcode }})
              </dougs-select-option>
            </dougs-select>
          </div>
        </div>
        <div class="form-layout mb-16">
          <dougs-field
            formControlName="street"
            [field]="companyFields['metadata.companyTaxesService.street']"
          ></dougs-field>
        </div>
        <div class="form-layout mb-16">
          <div class="form-layout__two-columns-small-first">
            <dougs-field
              formControlName="zipcode"
              [field]="companyFields['metadata.companyTaxesService.zipcode']"
            ></dougs-field>
            <dougs-field
              formControlName="city"
              [field]="companyFields['metadata.companyTaxesService.city']"
            ></dougs-field>
          </div>
        </div>
        <div class="form-layout mb-16">
          <dougs-field
            formControlName="phone"
            [field]="companyFields['metadata.companyTaxesService.phone']"
          ></dougs-field>
        </div>
        <div class="form-layout">
          <dougs-field
            formControlName="webSite"
            [field]="companyFields['metadata.companyTaxesService.webSite']"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
