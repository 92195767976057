import { Pipe, PipeTransform } from '@angular/core';
import { Partner } from '@dougs/partners/dto';

@Pipe({
  name: 'associatePillPercentage',
  standalone: true,
})
export class AssociatePillPercentagePipe implements PipeTransform {
  transform(partner: Partner, totalShares: number | undefined): string | null {
    if (!totalShares || !partner.metadata?.associate?.shareCount) {
      return null;
    }
    const percentage: number = (partner.metadata.associate.shareCount / totalShares) * 100;

    return percentage.toFixed(2).replace(/\.?0*$/, '');
  }
}
