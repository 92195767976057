<div>
  <div dougsModalTitle>
    <h6 class="mr-16">Convention de mise à disposition</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div>
    <div dougsModalContent>
      <ng-container *ngIf="needHelpForCreateProvisionOfPremises === null">
        <dougs-avatar-message
          avatarUrl="images/avatar/small-david.jpg"
          fullName="David, Responsable juridique"
          size="medium"
        >
          <p class="small">
            Bonjour ! Le service juridique de Dougs vous accompagne dans la rédaction de la convention de mise à
            disposition.
          </p>
        </dougs-avatar-message>

        <div class="mt-32 mb-16">
          <p class="small">
            Si votre société est domiciliée chez vous, et que vous êtes propriétaire, elle peut vous verser un loyer en
            contrepartie de l'utilisation d'une partie du logement. Une convention de mise à disposition d'un local doit
            alors être rédigée entre les parties.
            <br />
            <a
              target="_blank"
              href="
              https://www.dougs.fr/blog/comment-mettre-en-place-une-convention-de-mise-a-disposition/"
            >
              Plus d’infos ici
            </a>
          </p>
        </div>
        <div class="table-border">
          <div class="establishment-modal-table table-border-bottom">
            <p>Conseil et accompagnement par un juriste</p>
            <b>Gratuit</b>
          </div>
          <div class="establishment-modal-table">
            <p>Honoraires du service juridique de Dougs</p>
            <p>
              <b>80€</b>
              HT
            </p>
          </div>
        </div>
        <div class="mt-16 need-secondary-establishement-question">
          <h6 class="mb-16">Souhaitez-vous nous confier la déclaration de votre convention de mise à disposition ?</h6>
          <div class="mb-16">
            <dougs-button
              class="mr-8"
              type="button"
              color="secondary"
              (click)="needHelpForCreateProvisionOfPremises = true"
            >
              OUI
            </dougs-button>
            <dougs-button
              dougsModalClose
              type="button"
              color="secondary"
              (click)="needHelpForCreateProvisionOfPremises = false"
            >
              NON
            </dougs-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="needHelpForCreateProvisionOfPremises === true">
        <provision-of-premises-modal-form></provision-of-premises-modal-form>
      </ng-container>
    </div>
  </div>
</div>
