import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyActivity } from '@dougs/company/dto';
import {
  AccordionComponent,
  AccordionDescriptionDirective,
  AccordionFooterDirective,
  AccordionTitleDirective,
  ButtonComponent,
} from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyActivityService } from '../../../../../forms/form-company-activity.service';
import { CompanyActivityComponentService } from '../../../../../services/company-activities/company-activity.component.service';
import { CompanyActivityFormComponent } from '../company-activity-form/company-activity-form.component';

@Component({
  selector: 'company-activity',
  templateUrl: './company-activity.component.html',
  styleUrls: ['./company-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    CompanyActivityFormComponent,
    AccordionComponent,
    AccordionTitleDirective,
    AccordionDescriptionDirective,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    AccordionFooterDirective,
    ButtonComponent,
  ],
  providers: [CompanyActivityComponentService],
})
export class CompanyActivityComponent {
  @Input()
  set companyActivity(companyActivity: CompanyActivity) {
    this.companyActivityComponentService.setCompanyActivity(companyActivity);
  }

  constructor(
    public readonly companyActivityComponentService: CompanyActivityComponentService,
    public readonly userStateService: UserStateService,
    public readonly formCompanyActivityService: FormCompanyActivityService,
    public readonly fieldsStateService: FieldsStateService,
  ) {}

  protected readonly Array = Array;
}
