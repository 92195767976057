<ng-container *ngIf="establishmentComponentService.handleLastOpenedEstablishment$ | async"></ng-container>
<ng-container *ngIf="establishmentComponentService.handleCloseAccordionOnSubmit$ | async"></ng-container>

<ng-container *ngIf="establishmentComponentService.establishment$ | async as establishment">
  <form
    *ngIf="formEstablishmentsService.formGroup"
    [formGroup]="formEstablishmentsService.formGroup"
    (ngSubmit)="formEstablishmentsService.submitForm()"
  >
    <dougs-accordion
      [showFooter]="formEstablishmentsService.formGroup.dirty"
      [isOpen]="establishmentComponentService.accordionOpened$ | async"
      (open)="establishmentComponentService.setAccordionOpened($event)"
      icon="fa-building"
    >
      <dougs-accordion-title>
        <h5>{{ establishment.name ? establishment.name : establishment.street }}</h5>
      </dougs-accordion-title>
      <dougs-accordion-description>
        <dougs-pill type="error" *ngIf="establishmentComponentService.isClosed$ | async; else establishmentName">
          Fermé
          <span *ngIf="establishment.endDate">&nbsp;le {{ establishment.endDate | date: 'dd/MM/yyyy' }}</span>
        </dougs-pill>
        <ng-template #establishmentName>
          <p #establishmentName class="small">
            {{ establishment.name ? establishment.street + ', ' : '' }}{{ establishment.zipcode }}
            {{ establishment.city }}
          </p>
        </ng-template>
        <dougs-pill type="warning" *ngIf="!(establishmentComponentService.isEstablishmentCompleted$ | async)">
          Complété à
          {{
            (companyStateService.activeCompany$ | async)?.completion?.models?.establishments[establishment.id]
              ?.percent || 0
          }}%
        </dougs-pill>
      </dougs-accordion-description>
      <ng-container *ngIf="establishmentComponentService.fieldsLoading$()">
        <div *ngFor="let i of Array(7); let last = last" class="skeleton button" [class.mb-16]="!last"></div>
      </ng-container>
      <dougs-company-establishment-form
        *ngIf="
          (establishmentComponentService.showForm$ | async) && !this.establishmentComponentService.fieldsLoading$()
        "
        [establishmentFields]="fieldsStateService.establishmentFields$ | async"
        [establishment]="establishment"
      ></dougs-company-establishment-form>
      <dougs-accordion-footer>
        <div class="buttons">
          <dougs-button
            color="secondary"
            (click)="$event.stopPropagation(); establishmentComponentService.cancelEstablishment()"
          >
            Annuler
          </dougs-button>
          <dougs-button type="submit">Enregistrer</dougs-button>
        </div>
      </dougs-accordion-footer>
    </dougs-accordion>
  </form>
</ng-container>
