<ul class="associate-list mt-8 pl-0 mb-0">
  <li
    *ngFor="let currentAssociate of currentAssociates$ | async; trackBy: trackById"
    class="associate-list__item p-8"
    [ngClass]="{ 'py-8': inCreationModule, 'p-8': !inCreationModule }"
  >
    <div class="associate-list__item__container">
      <div class="associate-list__item__container__left">
        <dougs-avatar
          [image]="currentAssociate | partnerImageUrl"
          class="mr-8"
          [size]="inCreationModule ? 'small' : 'medium'"
        ></dougs-avatar>
        <h6 *ngIf="currentAssociate?.fullName">{{ currentAssociate.fullName }}</h6>
        <p *ngIf="!currentAssociate?.fullName" class="bold">Sans prénom</p>
      </div>
      <div class="associate-list__item__container__right">
        <dougs-pill>
          Associé
          <div *ngIf="(currentAssociate | associatePillPercentage: totalShares) !== null">
            <span class="pl-4 color-primary">●</span>
            {{ currentAssociate | associatePillPercentage: totalShares }}%
          </div>
        </dougs-pill>
        <p class="small" [ngClass]="{ italic: !currentAssociate.metadata?.associate?.shareCount }">
          {{
            !currentAssociate.metadata?.associate?.shareCount
              ? 'Aucune '
              : currentAssociate.metadata?.associate?.shareCount
          }}
          {{ legalForm | propertyType | async }}{{ currentAssociate.metadata?.associate?.shareCount > 1 ? 's' : '' }}
        </p>
        <dougs-button
          *ngIf="!inCreationModule"
          size="small"
          (click)="openAssociateModal(currentAssociate.id, company.id)"
        >
          <i class="fal fa-pencil color-white"></i>
        </dougs-button>
      </div>
    </div>
  </li>
</ul>
<dougs-button
  (click)="openAddPartnerModal(company.id)"
  *ngIf="company.legalFormConfig.allowMultipleAssociates && !inCreationModule"
  [fullWidth]="true"
  color="primary-light"
  class="mt-4"
>
  <i class="mr-8 color-primary fas fa-plus-circle"></i>
  Ajouter un associé
</dougs-button>
