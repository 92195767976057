import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, concatMap, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Establishment } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { EstablishmentsStateService } from '@dougs/settings/shared';

@Injectable()
export class EstablishmentsComponentService {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  refreshEstablishments$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.set(true)),
    concatMap((activeCompany) => this.establishmentsStateService.refreshEstablishments(activeCompany.id)),
    tap(() => this.isLoading.set(false)),
  );

  private readonly lastOpenedEstablishment: BehaviorSubject<Establishment | null> =
    new BehaviorSubject<Establishment | null>(null);
  lastOpenedEstablishment$ = this.lastOpenedEstablishment.asObservable();

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly establishmentsStateService: EstablishmentsStateService,
  ) {}

  setLastOpenedEstablishment(establishment: Establishment | null): void {
    this.lastOpenedEstablishment.next(establishment);
  }
}
