<ng-container *ngIf="requestSent === false">
  <dougs-avatar-message
    avatarUrl="images/avatar/small-david.jpg"
    fullName="David, Responsable juridique"
    size="medium"
    class="mb-16"
  >
    <p class="small">Aidez-nous à mieux vous accompagner en répondant aux questions suivantes :</p>
  </dougs-avatar-message>
  <form class="mt-48" (ngSubmit)="onSubmit()" [formGroup]="provisionOfPremisesService.form">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <dougs-form-field>
            <label for="establishementOwner" dougsFormFieldLabel class="mb-8">
              1. Êtes-vous propriétaire du bien concerné ?
            </label>
            <dougs-radio-group appearance="yesNo" formControlName="establishementOwner">
              <dougs-radio [value]="true">Oui</dougs-radio>
              <dougs-radio [value]="false">Non</dougs-radio>
            </dougs-radio-group>
          </dougs-form-field>
          <dougs-form-field>
            <label for="establishmentTotalArea" class="mb-8" dougsFormFieldLabel>
              2. Quelle est la surface du bien ?
            </label>
            <input
              id="establishmentTotalArea"
              dougsFormFieldControl
              formControlName="establishmentTotalArea"
              placeholder="70 m2"
            />
            <i dougsFormFieldSuffix class="fal fa-house"></i>
          </dougs-form-field>
          <dougs-form-field>
            <label for="establishmentAreaForRent" class="mb-8" dougsFormFieldLabel>
              3. Quelle surface de votre bien mettez-vous à disposition de votre société ?
            </label>
            <input
              id="establishmentAreaForRent"
              dougsFormFieldControl
              formControlName="establishmentAreaForRent"
              placeholder="20 m2"
            />
            <i dougsFormFieldSuffix class="fal fa-house"></i>
          </dougs-form-field>
          <dougs-form-field>
            <label for="establishmentRentAmount" class="mb-8" dougsFormFieldLabel>
              4. Quel est le montant de la valeur locative de votre bien dans sa globalité (quel serait le prix à payer
              si vous souhaitiez le mettre en location) ? Vous pouvez faire une estimation en ligne.
            </label>
            <input
              id="establishmentRentAmount"
              dougsFormFieldControl
              formControlName="establishmentRentAmount"
              placeholder="600 €"
            />
            <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
          </dougs-form-field>
          <dougs-form-field>
            <label for="establishmentMonthlyFees" class="mb-8" dougsFormFieldLabel>
              5. Quel est le montant mensuel pour l’ensemble des charges locatives suivantes : électricité, internet,
              eau, gaz ?
            </label>
            <input
              id="establishmentMonthlyFees"
              dougsFormFieldControl
              formControlName="establishmentMonthlyFees"
              placeholder="150 €"
            />
            <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
          </dougs-form-field>
          <dougs-input-datepicker
            formControlName="establishmentRentStartDate"
            label="6. À quelle date souhaitez-vous faire démarrer la convention ?"
            placeholder="12/01/2023"
          ></dougs-input-datepicker>
        </div>
      </div>
    </div>
    <div align="end" class="mb-16" dougsModalFooter>
      <dougs-button type="submit">Valider</dougs-button>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="requestSent === true">
  <request-sent-modal></request-sent-modal>
</ng-container>
