import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanySummaryPartnerData, CompanySummaryVehicleData } from '@dougs/accounting-survey/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';
import { AvatarComponent, DougsDatePipe, FilePillComponent, PanelInfoComponent } from '@dougs/ds';
import { CompanySummaryComponentService } from '../../../services/company-summary/company-summary.component.service';

@Component({
  selector: 'dougs-company-summary',
  standalone: true,
  imports: [CommonModule, PanelInfoComponent, AvatarComponent, FilePillComponent, DougsDatePipe],
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompanySummaryComponentService],
})
export class CompanySummaryComponent {
  private _accountingYear!: AccountingYear;

  get accountingYear(): AccountingYear {
    return this._accountingYear;
  }

  @Input()
  set accountingYear(value: AccountingYear) {
    this._accountingYear = value;
    this.companySummaryComponentService.setAccountingYear(this.accountingYear);
  }

  private _isAccountant = false;

  get isAccountant(): boolean {
    return this._isAccountant;
  }

  @Input()
  set isAccountant(value: boolean) {
    this._isAccountant = value;
    this.companySummaryComponentService.setIsAccountant(this.isAccountant);
  }

  @Input() disableBorder = false;

  constructor(public readonly companySummaryComponentService: CompanySummaryComponentService) {}

  trackByIndex(index: number): number {
    return index;
  }

  trackByPartnerId(_: number, companySummaryPartnerData: CompanySummaryPartnerData): number {
    return companySummaryPartnerData.partnerId;
  }

  trackByCarId(_: number, companySummaryVehicleData: CompanySummaryVehicleData): number {
    return companySummaryVehicleData.carId;
  }

  trackByAttachmentId(_: number, attachment: Attachment): number {
    return +attachment.id;
  }
}
