<ng-container *ngIf="companySummaryComponentService.refreshCompanySummary$ | async"></ng-container>
<div
  class="company-summary"
  *ngIf="companySummaryComponentService.companySummary$ | async as companySummary"
  [ngClass]="{ 'is-admin': isAccountant, 'no-border': disableBorder, 'mb-24 py-16 px-24': !disableBorder }"
>
  <h5 class="mb-8">{{ companySummary.title }}</h5>
  <div class="mb-8">
    <ul class="pl-24">
      <li *ngFor="let label of companySummary.description.data; trackBy: trackByIndex">
        <p class="small">{{ label }}</p>
      </li>
    </ul>
  </div>
  <dougs-panel-info
    type="admin"
    class="company-summary__configuration-changes mb-8"
    *ngIf="companySummary.configurationChanges.length && isAccountant"
  >
    <i class="fal fa-info-circle"></i>
    <ul>
      <li
        *ngFor="let confChanges of companySummary.configurationChanges; trackBy: trackByIndex"
        class="color-primary-700"
      >
        <b>Changement de {{ confChanges.label }}&nbsp;:</b>
        <ng-container [ngSwitch]="confChanges.configuration">
          <ng-container *ngSwitchCase="'legalForms'">
            Forme antérieure {{ confChanges.changes[1].value | uppercase }}, forme actuelle
            {{ confChanges.changes[0].value | uppercase }} depuis le
            {{ confChanges.changes[0].startDate | date: 'dd/MM/yyyy' }},
          </ng-container>
          <ng-container *ngSwitchCase="'vatConfigurations'">
            Régime TVA antérieur {{ confChanges.changes[1].value | uppercase }}, régime actuel
            {{ confChanges.changes[0].value | uppercase }} depuis le
            {{ confChanges.changes[0].startDate | date: 'dd/MM/yyyy' }},
          </ng-container>
          <ng-container *ngSwitchCase="'taxRegimes'">
            Régime fiscal antérieur {{ confChanges.changes[1].value | uppercase }}, régime actuel
            {{ confChanges.changes[0].value | uppercase }} depuis le
            {{ confChanges.changes[0].startDate | date: 'dd/MM/yyyy' }},
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </dougs-panel-info>
  <div class="mb-8" *ngIf="companySummary.activity && !isAccountant">
    <h6>{{ companySummary.activity.title }}</h6>
    <p class="small">{{ companySummary.activity.data[0] }}</p>
  </div>
  <div class="mb-8">
    <h6 *ngIf="!isAccountant" class="mb-8">{{ companySummary.partners?.title }}</h6>
    <ng-container *ngFor="let partner of companySummary.partners?.data; trackBy: trackByPartnerId">
      <div class="company-summary__partner mb-8">
        <dougs-avatar
          size="small"
          [image]="partner.avatarUrl ? partner.avatarUrl : 'https://www.gravatar.com/avatar/null?d=mm'"
        ></dougs-avatar>
        <p class="small">{{ partner.name }},&nbsp;{{ partner.label }}</p>
      </div>
      <dougs-panel-info type="admin" *ngIf="partner.companyLink" class="company-summary__partner">
        <i class="fal fa-info-circle"></i>
        Ce dossier est géré par Dougs
        <a [href]="partner.companyLink" target="_blank">Voir le dossier</a>
      </dougs-panel-info>
    </ng-container>
  </div>
  <div class="mb-8" *ngIf="companySummary.vehicles?.data.length">
    <h6 *ngIf="!isAccountant" class="mb-8">{{ companySummary.vehicles?.title }}</h6>
    <div
      class="company-summary__vehicle mb-8"
      *ngFor="let vehicle of companySummary.vehicles?.data; trackBy: trackByCarId"
    >
      <i class="fal" [ngClass]="companySummaryComponentService.getVehicleIcon(vehicle)"></i>
      <p class="small">{{ vehicle.name }},&nbsp;{{ vehicle.label }}</p>
    </div>
  </div>

  <ng-container *ngIf="!isAccountant && companySummary.files?.data.length">
    <div class="mb-8" *ngFor="let files of companySummary.files?.data; trackBy: trackByIndex">
      <h6>{{ files.title }}</h6>
      <div class="file-list">
        <dougs-file-pill
          *ngFor="let attachment of files.files; trackBy: trackByAttachmentId"
          [canDelete]="false"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
  </ng-container>
</div>
