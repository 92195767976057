import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ConfigBackService, TaxRegime } from '@dougs/core/config-back';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Field } from '@dougs/fields/dto';

@Component({
  selector: 'dougs-history-value-modal-tax-regime',
  templateUrl: './history-value-modal-tax-regime.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    NgIf,
    InputDatepickerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    SelectOptionComponent,
    ButtonComponent,
    DividerComponent,
    ModalFooterDirective,
  ],
})
export class HistoryValueModalTaxRegimeComponent implements OnInit {
  taxRegimes!: TaxRegime[];

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      company: Company;
      taxRegimeField: Field;
    },
    private readonly modalRef: ModalRef,
    public readonly configBackService: ConfigBackService,
  ) {}

  ngOnInit(): void {
    this.taxRegimes = this.data.company.taxRegimes;
  }

  removeHistoryValue(taxRegime: TaxRegime): void {
    const index: number = this.data.company.taxRegimes.indexOf(taxRegime);

    if (index >= 0) {
      this.taxRegimes.splice(index, 1);
    }
  }

  addHistoryValue(): void {
    this.taxRegimes.push({
      value: '',
      isFirst: false,
      startDate: new Date(),
    });
  }

  sortTaxRegimes(taxRegimes: TaxRegime[]): TaxRegime[] {
    return taxRegimes.sort((a: TaxRegime, b: TaxRegime) => {
      if (b.isFirst !== a.isFirst) {
        return +b.isFirst - +a.isFirst;
      }

      return +new Date(a.startDate) - +new Date(b.startDate);
    });
  }

  onSubmit(): void {
    this.modalRef.close(this.taxRegimes);
  }

  trackByValue(index: number, item: { value: number; label: string }): number {
    return item.value;
  }

  trackByTaxRegime(index: number, item: TaxRegime): TaxRegime['value'] {
    return item.value;
  }
}
