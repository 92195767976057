<div class="settings-card__content">
  <div class="settings-card__sticky-column-title">
    <h5 class="mb-16">Greffe</h5>
  </div>
  <div [formGroup]="formCompanyService.formGroup" id="metadatacourtsAdministrationServicename-company">
    <ng-container formGroupName="metadata">
      <ng-container formGroupName="courtsAdministrationService">
        <div class="form-layout mb-16">
          <div>
            <label dougsFormFieldLabel id="metadata.courtsAdministrationService.name">Greffe compétent</label>
            <dougs-select
              dougsFormFieldControl
              bindValue="name"
              [searchable]="true"
              maxHeight="300"
              [ngModel]="formCompanyService.selectedCourtAdministration"
              (ngModelChange)="formCompanyService.updateCourtAdministrationService($event)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!companyFields['metadata.courtsAdministrationService.name']?.isEditable"
              placeholder="-- Greffe compétent --"
            >
              <dougs-select-option
                *ngFor="let option of configBackService.courtAdministrationServices$ | async; trackBy: trackByName"
                [value]="option"
              >
                {{ option.name }} ({{ option.zipcode }})
              </dougs-select-option>
            </dougs-select>
          </div>
        </div>
        <div
          class="form-layout mb-16"
          *ngIf="companyFields['metadata.courtsAdministrationService.street']?.isAvailable"
        >
          <dougs-field
            formControlName="street"
            [field]="companyFields['metadata.courtsAdministrationService.street']"
          ></dougs-field>
        </div>
        <div
          class="form-layout mb-16"
          *ngIf="
            companyFields['metadata.courtsAdministrationService.zipcode']?.isAvailable ||
            companyFields['metadata.courtsAdministrationService.city']?.isAvailable
          "
        >
          <div class="form-layout__two-columns-small-first">
            <dougs-field
              formControlName="zipcode"
              [field]="companyFields['metadata.courtsAdministrationService.zipcode']"
            ></dougs-field>
            <dougs-field
              formControlName="city"
              [field]="companyFields['metadata.courtsAdministrationService.city']"
            ></dougs-field>
          </div>
        </div>
        <div class="form-layout mb-16" *ngIf="companyFields['metadata.courtsAdministrationService.phone']?.isAvailable">
          <dougs-field
            formControlName="phone"
            [field]="companyFields['metadata.courtsAdministrationService.phone']"
          ></dougs-field>
        </div>
        <div class="form-layout" *ngIf="companyFields['metadata.courtsAdministrationService.webSite']?.isAvailable">
          <dougs-field
            formControlName="webSite"
            [field]="companyFields['metadata.courtsAdministrationService.webSite']"
          ></dougs-field>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
