<div>
  <div *ngIf="!requestSent">
    <div dougsModalTitle>
      <h6 class="mr-16">Ajouter un établissement secondaire</h6>
      <i dougsModalClose class="fal fa-times"></i>
    </div>
    <div dougsModalContent>
      <div>
        <dougs-avatar-message
          [avatarUrl]="'images/avatar/small-david.jpg'"
          fullName="David, Responsable juridique"
          size="medium"
        >
          <p class="small">
            Bonjour ! Le service juridique de Dougs peut s’occuper de déclarer votre établissement secondaire.
          </p>
        </dougs-avatar-message>
        <div class="my-16">
          <p class="small">
            Tout établissement secondaire doit obligatoirement être immatriculé au RCS afin de déclarer votre CFE
            annuelle (Cotisation Foncière des Entreprises). Les entreprises sont assujetties à cette cotisation dans
            chaque commune où elles disposent de locaux et de terrains.
          </p>
        </div>
        <div class="table-border">
          <div class="establishment-modal-table table-border-bottom">
            <p>Premier RDV téléphonique sans engagement</p>
            <b>Gratuit</b>
          </div>
          <div class="establishment-modal-table table-border-bottom">
            <p>Honoraires du service juridique de Dougs</p>
            <p>
              <b>80€</b>
              HT
            </p>
          </div>
          <div class="establishment-modal-table">
            <p>Frais de Greffe du Tribunal de Commerce</p>
            <p class="need-secondary-establishement-price">
              entre
              <b>70€</b>
              HT et
              <b>110€</b>
              HT
              <br />
              selon l’emplacement de l’établissement
            </p>
          </div>
        </div>
      </div>
      <div class="mt-16 need-secondary-establishement-question">
        <h6>Souhaitez-vous nous confier la déclaration de votre établissement secondaire ?</h6>
        <dougs-button
          class="mr-8"
          type="button"
          [color]="needHelpForCreateEstablishement ? 'primary' : 'secondary'"
          (click)="needHelpForCreateEstablishement = true"
        >
          OUI
        </dougs-button>
        <dougs-button
          type="button"
          [color]="needHelpForCreateEstablishement === false ? 'primary' : 'secondary'"
          (click)="needHelpForCreateEstablishement = false"
        >
          NON
        </dougs-button>
      </div>
      <div *ngIf="needHelpForCreateEstablishement">
        <h6 class="mb-16 mt-16">Très bien ! Pour vous accompagner, nous avons besoin de plusieurs informations</h6>
        <div>
          <fieldset id="establishment-fieldset">
            <legend>Adresse de l'établissement secondaire</legend>
            <form [formGroup]="form">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    <dougs-form-field size="large">
                      <label for="street" dougsFormFieldLabel>Numéro et rue</label>
                      <input
                        id="street"
                        dougsFormFieldControl
                        type="text"
                        formControlName="street"
                        placeholder="Numéro et rue"
                      />
                    </dougs-form-field>
                    <dougs-form-field size="large">
                      <label for="zipcode" dougsFormFieldLabel>Code Postal</label>
                      <input
                        id="zipcode"
                        dougsFormFieldControl
                        type="text"
                        formControlName="zipcode"
                        placeholder="Code Postal"
                      />
                    </dougs-form-field>
                    <dougs-form-field size="large">
                      <label for="city" dougsFormFieldLabel>Ville</label>
                      <input id="city" dougsFormFieldControl type="text" formControlName="city" placeholder="Ville" />
                    </dougs-form-field>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
      <div *ngIf="needHelpForCreateEstablishement === false">
        <dougs-panel-info class="mb-16">
          <p>
            Vous devez déclarer vous-même votre établissement secondaire. Dougs le détectera automatiquement la
            prochaine fois que vous catégoriserez le loyer de votre établissement secondaire.
          </p>
        </dougs-panel-info>
      </div>
    </div>
    <div *ngIf="needHelpForCreateEstablishement" align="end" class="mb-16" dougsModalFooter>
      <dougs-button class="mr-8" color="secondary" dougsModalClose>Annuler</dougs-button>
      <dougs-button type="submit" (click)="onSubmit()">Valider</dougs-button>
    </div>
  </div>
  <div *ngIf="requestSent">
    <div dougsModalTitle>
      <h6 class="mr-16">Ajouter un établissement secondaire</h6>
      <i dougsModalClose class="fal fa-times"></i>
    </div>
    <div dougsModalContent>
      <dougs-blank-state
        maxWidth="200"
        [autoWidth]="true"
        illustration="etablissement-secondaire-blank-slate"
      ></dougs-blank-state>
      <h5 class="mt-16 mb-16 need-secondary-establishement-resquet-sent">Demande envoyée</h5>
      <p class="need-secondary-establishement-resquet-sent">
        Nous avons bien pris en compte votre demande de déclaration d'établissement secondaire. Surveillez vos
        notifications, nous allons vous recontacter rapidement avec un devis personnalisé.
      </p>
    </div>
  </div>
  <div
    *ngIf="requestSent || needHelpForCreateEstablishement === false"
    class="need-secondary-establishement-resquet-sent-button"
    dougsModalFooter
  >
    <dougs-button color="primary" dougsModalClose>OK</dougs-button>
  </div>
</div>
