import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, concatMap, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyActivity } from '@dougs/company/dto';
import { CompanyActivityStateService, CompanyStateService } from '@dougs/company/shared';
import { FieldsStateService } from '@dougs/fields/shared';

@Injectable()
export class CompanyActivitiesComponentService {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  refreshCompanyActivities$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.set(true)),
    concatMap((activeCompany) => this.companyActivityStateService.refreshCompanyActivities(activeCompany.id)),
    tap(() => this.isLoading.set(false)),
  );

  private readonly lastOpenedCompanyActivity: BehaviorSubject<CompanyActivity | null> =
    new BehaviorSubject<CompanyActivity | null>(null);
  lastOpenedCompanyActivity$ = this.lastOpenedCompanyActivity.asObservable();

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly companyActivityStateService: CompanyActivityStateService,
    private readonly fieldsStateService: FieldsStateService,
  ) {}

  setLastOpenedCompanyActivity(companyActivity: CompanyActivity | null): void {
    this.lastOpenedCompanyActivity.next(companyActivity);
  }

  async addNewCompanyActivity(): Promise<void> {
    const companyActivityAdded: CompanyActivity | null = await this.companyActivityStateService.addCompanyActivity(
      this.companyStateService.activeCompany.id,
    );

    if (companyActivityAdded) {
      this.setLastOpenedCompanyActivity(companyActivityAdded);
      await this.fieldsStateService.refreshCompanyActivityFields(companyActivityAdded);
    }
  }
}
