import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import {
  AvatarMessageComponent,
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { ProvisionOfPremisesService } from '../../services/provision-of-premises.service';
import { ProvisionOfPremisesModalFormComponent } from './provision-of-premises-modal-form/provision-of-premises-modal-form.component';

@Component({
  selector: 'provision-of-premises-modal',
  templateUrl: './provision-of-premises-modal.component.html',
  styleUrls: ['./provision-of-premises-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    AvatarMessageComponent,
    ButtonComponent,
    ProvisionOfPremisesModalFormComponent,
  ],
  providers: [ProvisionOfPremisesService],
})
export class ProvisionOfPremisesModalComponent {
  needHelpForCreateProvisionOfPremises: boolean | null = null;

  constructor(private readonly metricsService: MetricsService) {
    this.metricsService.pushMixpanelEvent('Accounting Provision Of Premises Modal Viewed');
  }
}
