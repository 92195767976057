<div [formGroup]="formCompanyActivityService.formGroup">
  <ng-container *ngIf="formCompanyActivityService.formGroupChanges$ | async"></ng-container>
  <dougs-field
    [field]="companyActivityFields.companyActivity['activity']"
    class="mb-16"
    formControlName="activity"
  ></dougs-field>
  <ng-container
    *ngIf="companyActivityFields.companyActivity['inseeActivityCode']?.isAvailable"
    formGroupName="inseeActivityCode"
  >
    <div
      *ngIf="companyActivityFields.companyActivity['inseeActivityCode.code0']?.isAvailable"
      class="form-layout mb-16"
    >
      <dougs-field
        (ngModelChange)="formCompanyActivityService.updateInseeActivityServiceCode('code0')"
        [field]="companyActivityFields.companyActivity['inseeActivityCode.code0']"
        [trackSelectOptionsByLabel]="true"
        formControlName="code0"
      ></dougs-field>
    </div>
    <div
      *ngIf="companyActivityFields.companyActivity['inseeActivityCode.code1']?.isAvailable"
      class="form-layout mb-16"
    >
      <dougs-field
        (ngModelChange)="formCompanyActivityService.updateInseeActivityServiceCode('code1')"
        [field]="companyActivityFields.companyActivity['inseeActivityCode.code1']"
        [trackSelectOptionsByLabel]="true"
        formControlName="code1"
      ></dougs-field>
    </div>
    <div
      *ngIf="companyActivityFields.companyActivity['inseeActivityCode.code2']?.isAvailable"
      class="form-layout mb-16"
    >
      <dougs-field
        (ngModelChange)="formCompanyActivityService.updateInseeActivityServiceCode('code2')"
        [field]="companyActivityFields.companyActivity['inseeActivityCode.code2']"
        [trackSelectOptionsByLabel]="true"
        formControlName="code2"
      ></dougs-field>
    </div>
    <div
      *ngIf="companyActivityFields.companyActivity['inseeActivityCode.code3']?.isAvailable"
      class="form-layout mb-16"
    >
      <dougs-field
        [field]="companyActivityFields.companyActivity['inseeActivityCode.code3']"
        [trackSelectOptionsByLabel]="true"
        formControlName="code3"
      ></dougs-field>
    </div>
  </ng-container>
  <dougs-divider class="mb-16"></dougs-divider>
  <dougs-field
    [field]="companyActivityFields.companyActivity['isJqpaNeeded']"
    [flex]="true"
    class="mb-16"
    formControlName="isJqpaNeeded"
  ></dougs-field>
  <div *ngIf="companyActivityFields.companyActivity['attachments'].isAvailable">
    <dougs-field
      (deleteFile)="formCompanyActivityService.deleteFile(companyActivity, $event)"
      (uploadFile)="formCompanyActivityService.uploadFiles(companyActivity, $event)"
      [field]="companyActivityFields.companyActivity['attachments']"
      [showLabel]="false"
      class="mb-16"
      formControlName="attachments"
    ></dougs-field>
  </div>
  <ng-container *ngIf="companyActivity.id && !companyActivity.isMainActivity">
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350 mb-16">
      Voulez-vous supprimer cette activité ?
      <a
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          companyActivityComponentService.deleteCompanyActivity(companyActivity)
        "
        class="color-error"
        href="#"
      >
        Supprimer
      </a>
    </p>
  </ng-container>
</div>
