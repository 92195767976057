import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  Observable,
} from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CompanyActivity } from '@dougs/company/dto';
import { CompanyActivityStateService } from '@dougs/company/shared';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { FormCompanyActivityService } from '../../forms/form-company-activity.service';
import { CompanyActivitiesComponentService } from './company-activities.component.service';

@Injectable()
export class CompanyActivityComponentService {
  handleLastOpenedCompanyActivity$: Observable<void> =
    this.companyActivitiesComponentService.lastOpenedCompanyActivity$.pipe(
      distinctUntilChanged(),
      tap((companyActivity: CompanyActivity | null) => this.handleAccordionOpen(companyActivity)),
      filter(
        (companyActivity: CompanyActivity | null): companyActivity is CompanyActivity =>
          !!companyActivity && companyActivity.id === this.companyActivity.value?.id,
      ),
      tap(() => this.fieldsLoading.set(true)),
      concatMap((companyActivity: CompanyActivity) =>
        this.fieldsStateService.refreshCompanyActivityFields(companyActivity),
      ),
      tap(() => this.fieldsLoading.set(false)),
    );

  private readonly fieldsLoading: WritableSignal<boolean> = signal(false);
  fieldsLoading$: Signal<boolean> = this.fieldsLoading.asReadonly();

  handleCloseAccordionOnSubmit$: Observable<void> = this.formCompanyActivityService.closeCompanyActivityAccordion$.pipe(
    filter((companyActivity: CompanyActivity) => companyActivity.id === this.companyActivity.value?.id),
    map(() => this.setAccordionOpened(false)),
  );

  private readonly companyActivity: BehaviorSubject<CompanyActivity | null> =
    new BehaviorSubject<CompanyActivity | null>(null);
  companyActivity$: Observable<CompanyActivity | null> = this.companyActivity.asObservable();

  private readonly accordionOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  accordionOpened$: Observable<boolean> = this.accordionOpened.asObservable();

  showForm$: Observable<boolean> = combineLatest([
    this.fieldsStateService.companyActivityFields$,
    this.companyActivitiesComponentService.lastOpenedCompanyActivity$,
    this.accordionOpened$,
  ]).pipe(
    map(
      ([fields, lastOpenedCompanyActivity, isOpen]) =>
        !!fields &&
        !!lastOpenedCompanyActivity &&
        isOpen &&
        lastOpenedCompanyActivity.id === this.companyActivity.value?.id,
    ),
  );

  constructor(
    private readonly formCompanyActivityService: FormCompanyActivityService,
    private readonly companyActivitiesComponentService: CompanyActivitiesComponentService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyActivityStateService: CompanyActivityStateService,
    private readonly modalService: ModalService,
  ) {}

  setAccordionOpened(opened: boolean): void {
    if (opened) {
      this.companyActivitiesComponentService.setLastOpenedCompanyActivity(this.companyActivity.value);
    }
    this.accordionOpened.next(opened);
  }

  setCompanyActivity(companyActivity: CompanyActivity): void {
    this.companyActivity.next(companyActivity);
  }

  async deleteCompanyActivity(companyActivity: CompanyActivity): Promise<void> {
    const { data } = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: "Supprimer l'activité",
            body: `Êtes-vous sûr de vouloir supprimer votre activité ? Cette action est définitive.`,
            noText: 'Annuler',
            yesText: 'Oui, supprimer mon activité',
          },
        })
        .afterClosed$.pipe(take(1)),
    );

    if (data) {
      await this.companyActivityStateService.deleteCompanyActivity(companyActivity);
    }
  }

  cancelCompanyActivity(): void {
    this.formCompanyActivityService.formGroup.markAsUntouched();
    this.formCompanyActivityService.formGroup.markAsPristine();
    this.setAccordionOpened(false);
  }

  private handleAccordionOpen(companyActivity: CompanyActivity | null): void {
    if (this.companyActivity.value?.id !== companyActivity?.id) {
      this.setAccordionOpened(false);
    } else if (!this.accordionOpened.value) {
      this.setAccordionOpened(true);
    }
  }
}
