import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingStats, AccountingSurvey, AccountingSurveyHttpOptions } from '@dougs/accounting-survey/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class AccountingSurveyHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccountingSurvey(companyId: number, accountingYearId: number): Observable<AccountingSurvey> {
    return this.http.get<AccountingSurvey>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/accounting-survey`,
    );
  }

  removeAttachment(survey: AccountingSurvey, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/accounting-years/${survey.accountingYearId}/accounting-survey/attachments/${attachment.id}`,
    );
  }

  updateAccountingSurvey(
    survey: AccountingSurvey,
    options?: AccountingSurveyHttpOptions,
  ): Observable<AccountingSurvey> {
    let params = new HttpParams();

    if (options?.forceComplete) {
      params = params.append('forceComplete', options.forceComplete);
    }

    if (options?.validate) {
      params = params.append('validate', options.validate);
    }

    if (options?.validateGroup) {
      params = params.append('validateGroup', options.validateGroup);
    }

    if (options?.editGroup) {
      params = params.append('editGroup', options.editGroup);
    }

    return this.http.post<AccountingSurvey>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/accounting-years/${survey.accountingYearId}/accounting-survey`,
      survey,
      { params },
    );
  }

  reopenAccountingSurvey(
    survey: AccountingSurvey,
    options: {
      reopenForCustomer: boolean;
      dueDate?: Date;
      emailContent?: string;
      applyPenalty?: boolean;
      unlockAccounts?: boolean;
    },
  ): Observable<AccountingSurvey> {
    let body: any = {
      reopenForCustomer: options.reopenForCustomer,
    };

    if (options.dueDate && options.emailContent) {
      body = {
        ...options,
      };
    }

    return this.http.post<AccountingSurvey>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/accounting-years/${survey.accountingYearId}/accounting-survey/actions/reopen`,
      body,
    );
  }

  uploadAccountingSurveyAttachment(
    survey: AccountingSurvey,
    file: File,
    type: string,
    metadata?: { partnerId?: number; type?: string; loanId?: number },
  ): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let params: HttpParams = new HttpParams().append('filename', file.name).append('type', type);

    if (metadata) {
      params = params.append('metadata', JSON.stringify(metadata));
    }

    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/accounting-years/${survey.accountingYearId}/accounting-survey/attachments`,
      formData,
      { params },
    );
  }

  getAccountingStats(survey: AccountingSurvey): Observable<AccountingStats> {
    const params: HttpParams = new HttpParams().append('accountingYearId', survey.accountingYearId);

    return this.http.get<AccountingStats>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${survey.companyId}/accounting-stats`,
      {
        params,
      },
    );
  }
}
