<dougs-form-field [noMargin]="noMargin" [size]="size">
  <label dougsFormFieldLabel *ngIf="showLabel" class="mb-4">{{ label }}</label>
  <dougs-select
    *ngIf="(!showSearch && company) || (!showSearch && clickToSearch)"
    (click)="showSearch = true"
    class="select-company"
    dougsFormFieldControl
    [ngModel]="company"
    bindValue="siren"
    placeholder="Sélectionner une entreprise"
  >
    <dougs-select-option *ngIf="company" [value]="company">
      <b>{{ company.legalName }}</b>
      - {{ company.siren }} - {{ company.headquarter?.zipcode }}&nbsp;{{ company.headquarter?.city }}
      <!-- TODO check why headquarter is null when company is provided from CreateUserModalComponent -->
    </dougs-select-option>
  </dougs-select>
  <span dougsFormFieldError *ngIf="selectedCompanyError && !showSearch">Ce champ est requis</span>
  <div class="search-company" *ngIf="showSearch || (!company && !clickToSearch)">
    <input
      [formControl]="searchSirenControl"
      type="text"
      [ngClass]="{ 'mb-8': !isPositionAbsolute }"
      placeholder="Nom entreprise ou n° de SIREN"
      [dougsAutofocus]="hasAutofocus"
      dougsFormFieldControl
    />
    <ng-container *ngIf="searchSiren$ | async as companies">
      <div
        class="search-company__results"
        [ngClass]="{ 'search-company__results--absolute': isPositionAbsolute }"
        *ngIf="companies.length"
      >
        <dougs-loader *ngIf="isLoadingSearch$ | async"></dougs-loader>
        <ul class="search-company__results__list" *ngIf="companies.length && !(isLoadingSearch$ | async)">
          <li
            class="search-company__results__list__item pointer p-8"
            *ngFor="let company of companies; trackBy: trackBySiren"
            (click)="setCompany(company)"
          >
            <b>{{ company.legalName }}</b>
            - {{ company.siren }} - {{ company.headquarter.zipcode }}&nbsp;{{ company.headquarter.city }}
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</dougs-form-field>
