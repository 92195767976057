<div dougsModalTitle>
  <h6>Sélectionner une personne</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-form-field class="mb-8">
    <i class="fal fa-search" dougsFormFieldPrefix></i>
    <input type="text" [formControl]="searchControl" dougsFormFieldControl placeholder="Rechercher une personne" />
  </dougs-form-field>
  <ul class="partners-list px-0">
    <li
      class="partners-list__item p-8 pointer"
      *ngFor="let partner of itemSearch$ | async; trackBy: trackById"
      (click)="openAssociateModal(partner, data.companyId, true)"
    >
      <dougs-avatar [image]="partner | partnerImageUrl" class="mr-8" size="medium"></dougs-avatar>
      <h6 *ngIf="partner?.fullName">{{ partner.fullName }}</h6>
      <p *ngIf="!partner?.fullName" class="bold">Non renseigné</p>
    </li>
  </ul>
  <dougs-blank-state illustration="empty-search" *ngIf="(itemSearch$ | async)?.length === 0">
    <p class="small mt-16">
      Aucune personne ne correspond à votre recherche,
      <button
        (click)="openCreateAssociateModal(data.companyId)"
        class="partners-list__create-button pointer color-primary px-0"
      >
        créez-la ici
      </button>
      .
    </p>
  </dougs-blank-state>
  <dougs-panel-info *ngIf="(itemSearch$ | async)?.length !== 0">
    La personne n'est pas dans la liste ?
    <button
      (click)="openCreateAssociateModal(data.companyId)"
      class="partners-list__create-button pointer color-primary"
    >
      Créez-la ici
    </button>
  </dougs-panel-info>
</div>
