<ng-container *ngIf="companyActivityComponentService.handleLastOpenedCompanyActivity$ | async"></ng-container>
<ng-container *ngIf="companyActivityComponentService.handleCloseAccordionOnSubmit$ | async"></ng-container>
<ng-container *ngIf="companyActivityComponentService.companyActivity$ | async as companyActivity">
  <form
    *ngIf="formCompanyActivityService.formGroup"
    [formGroup]="formCompanyActivityService.formGroup"
    (ngSubmit)="formCompanyActivityService.submitForm()"
  >
    <dougs-accordion
      [isAdmin]="true"
      [showFooter]="formCompanyActivityService.formGroup.dirty"
      [isOpen]="companyActivityComponentService.accordionOpened$ | async"
      (open)="companyActivityComponentService.setAccordionOpened($event)"
      [icon]="companyActivity.isMainActivity ? 'fa-rocket-launch' : 'fa-building'"
    >
      <dougs-accordion-title>
        <h5 [ngClass]="{ 'color-admin': companyActivity.isMainActivity }">
          {{ companyActivity.isMainActivity ? 'Activité principale' : 'Activité secondaire' }}
        </h5>
      </dougs-accordion-title>
      <dougs-accordion-description>
        <p class="small overflow-ellipsis">{{ companyActivity.activity ?? 'Non renseignée' }}</p>
      </dougs-accordion-description>
      <ng-container *ngIf="companyActivityComponentService.fieldsLoading$()">
        <div *ngFor="let i of Array(7); let last = last" class="skeleton button" [class.mb-16]="!last"></div>
      </ng-container>
      <dougs-company-activity-form
        *ngIf="
          (companyActivityComponentService.showForm$ | async) && !this.companyActivityComponentService.fieldsLoading$()
        "
        [companyActivityFields]="fieldsStateService.companyActivityFields$ | async"
        [companyActivity]="companyActivity"
      ></dougs-company-activity-form>
      <dougs-accordion-footer>
        <div class="buttons">
          <dougs-button
            color="secondary"
            (click)="$event.stopPropagation(); companyActivityComponentService.cancelCompanyActivity()"
          >
            Annuler
          </dougs-button>
          <dougs-button type="submit">Enregistrer</dougs-button>
        </div>
      </dougs-accordion-footer>
    </dougs-accordion>
  </form>
</ng-container>
