import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, LegalForms } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { MetricsService } from '@dougs/core/metrics';
import { FileStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { FormCompanyService } from '../forms/form-company.service';

@Injectable()
export class CompanyComponentService {
  public isRefreshingCompanyFromSiren = false;

  shouldShowRefreshCompanyButton$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin ||
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  constructor(
    private readonly formCompanyService: FormCompanyService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly fileStateService: FileStateService,
    private readonly logger: LoggerService,
    private readonly metricsService: MetricsService,
  ) {}

  async uploadLogo(file: File, company: Company): Promise<void> {
    await this.fileStateService.uploadFiles(company, 'invoicingLogo', 'invoicingLogo', file);
  }

  async deleteLogo(event: MouseEvent, company: Company) {
    event.preventDefault();
    if (!company.invoicingLogo) {
      return;
    }
    await this.fileStateService.deleteFiles(company, company.invoicingLogo, 'invoicingLogo', false);
  }

  async refreshCompanyFromSiren(company: Company): Promise<void> {
    try {
      this.isRefreshingCompanyFromSiren = true;

      this.metricsService.pushMixpanelEvent('Data Company Called ', {
        Location: 'Settings Refresh',
      });

      await this.companyStateService.refreshCompanyDataFromSiren(company.id);
      this.formCompanyService.formGroup.markAsPristine();
      await this.companyStateService.refreshCompany();
      this.isRefreshingCompanyFromSiren = false;
    } catch (e) {
      this.isRefreshingCompanyFromSiren = false;
      this.logger.error(e);
    }
  }

  sortLegalForm(legalForms: LegalForms[]): LegalForms[] {
    return legalForms.sort((a: LegalForms, b: LegalForms) => {
      if (b.isFirst !== a.isFirst) {
        return +b.isFirst - +a.isFirst;
      }

      return +new Date(a.startDate) - +new Date(b.startDate);
    });
  }
}
