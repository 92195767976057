import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { from, lastValueFrom, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Establishment } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { FormSettingsService } from '@dougs/core/form';
import { MetricsService } from '@dougs/core/metrics';
import { mergeObjects } from '@dougs/core/utils';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { AllFields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { EstablishmentsStateService } from '@dougs/settings/shared';

const ESTABLISHMENT_FIELDS_SCHEMA: string[] = [
  'name',
  'siret',
  'ape',
  'street',
  'city',
  'zipcode',
  'establishmentRentContract',
  'registrationDate',
  'establishmentOwnerId',
  'endDate',
  'provisionOfPremises',
];

@Injectable({
  providedIn: 'root',
})
export class FormEstablishmentsService extends FormSettingsService {
  private readonly closeEstablishmentAccordion: Subject<Establishment> = new Subject<Establishment>();
  closeEstablishmentAccordion$: Observable<Establishment> = this.closeEstablishmentAccordion.asObservable();

  willCloseEstablishmentControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  establishment!: Establishment;

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly establishmentStateService: EstablishmentsStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
    private readonly modalService: ModalService,
  ) {
    super(ESTABLISHMENT_FIELDS_SCHEMA);
  }

  public formatForm(fields: AllFields): void {
    this.formatFieldsForm(fields.establishment);
  }

  public populateForm(establishment: Establishment): void {
    this.populateFieldsForm(establishment);
    this.establishment = establishment;
    this.willCloseEstablishmentControl.setValue(!!establishment.endDate || !establishment.isActive, {
      emitEvent: false,
    });
    this.listenFormGroupChanges();
  }

  public listenFormGroupChanges(): void {
    this.listenOnFormGroupChanges((values) =>
      from(
        this.fieldsStateService.refreshEstablishmentFields(
          this.establishment.companyId || this.companyStateService.activeCompany.id,
          mergeObjects(this.establishment, values),
        ),
      ),
    );
  }

  async submitForm(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      if (this.willCloseEstablishmentControl.value) {
        this.metricsService.pushMixpanelEvent('Accounting Closing Secondary Establishment Modal Viewed');

        const success: boolean | undefined | null = (
          await lastValueFrom(
            this.modalService
              .open<boolean>(ConfirmationModalComponent, {
                data: {
                  title: "Fermer l'établissement secondaire",
                  body: `
                Souhaitez-vous vraiment fermer cet établissement ?
                <br/>Notre service juridique prendra contact avec vous pour vous conseiller sur les procédures à suivre.
              `,
                  noText: 'Annuler',
                  yesText: 'Oui, je veux fermer mon établissement',
                },
              })
              .afterClosed$.pipe(take(1)),
          )
        )?.data;

        if (!success) {
          return;
        }

        await this.establishmentStateService.createClosingSecondaryEstablishmentTask(
          this.establishment.companyId,
          this.establishment,
        );
      }

      const updatedEstablishment: Establishment | null = await this.establishmentStateService.updateEstablishment(
        this.establishment.companyId || this.companyStateService.activeCompany.id,
        mergeObjects(this.establishment, this.formGroup.value),
      );

      if (updatedEstablishment) {
        this.closeEstablishmentAccordion.next(updatedEstablishment);
      }
    }
  }
}
