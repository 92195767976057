import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { DividerComponent, LoaderComponent, TrackByPipe } from '@dougs/ds';
import { EstablishmentsStateService } from '@dougs/settings/shared';
import { FormEstablishmentsService } from '../../../../forms/form-establishments.service';
import { EstablishmentsComponentService } from '../../../../services/establishments/establishments.component.service';
import { CompanyEstablishmentComponent } from './company-establishment/company-establishment.component';

@Component({
  selector: 'dougs-company-establishments',
  standalone: true,
  imports: [CommonModule, CompanyEstablishmentComponent, DividerComponent, TrackByPipe, LoaderComponent],
  templateUrl: './company-establishments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EstablishmentsComponentService, FormEstablishmentsService],
})
export class CompanyEstablishmentsComponent {
  constructor(
    public readonly establishmentsComponentService: EstablishmentsComponentService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}
}
