<dougs-form-field *ngIf="field.input?.type === 'select'" [noMargin]="true" [size]="size">
  <ng-lottie
    *ngIf="missingFieldsBadgeService.shouldShowMissingFieldsBadge$ | async"
    width="16px"
    height="16px"
    containerClass="missing-field-animation"
    [options]="options"
  ></ng-lottie>
  <div *ngIf="showLabel" dougsFormFieldLabel class="field-label-wrapper">
    <label
      [id]="labelId"
      [for]="generatedId"
      dougsFormFieldLabel
      [innerHTML]="field?.label"
      [isAdmin]="isAdmin"
    ></label>
    <i class="fal fa-question-circle help ml-4" *ngIf="field.description" (click)="showHelpModal()"></i>
  </div>
  <dougs-select
    maxHeight="250"
    dougsFormFieldControl
    [id]="generatedId"
    [ngModel]="value"
    [placeholder]="field.input?.labels?.defaultText || ''"
    [disabled]="!field.isEditable || isDisabled"
    (ngModelChange)="onSelectChange($event)"
    [required]="field.input?.isRequired"
  >
    <dougs-select-option *ngFor="let option of field.input.labels?.items; trackBy: trackByValue" [value]="option.value">
      {{ option.label }}
    </dougs-select-option>
  </dougs-select>
  <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
    <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
    <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
  </span>
</dougs-form-field>
