import { Injectable } from '@angular/core';
import { concatMap, map, Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';

@Injectable()
export class CompanySummaryModalComponentService {
  constructor(
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  refreshAccountingYears$: Observable<AccountingYear[]> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((company: Company) => this.accountingYearStateService.refreshAccountingYears(company.id)),
  );

  lastAccountingYear$: Observable<AccountingYear | undefined> = this.accountingYearStateService.accountingYears$.pipe(
    map((accountingYears: AccountingYear[]) => this.getLastAccountingYear(accountingYears)),
  );

  private getLastAccountingYear(accountingYears: AccountingYear[]): AccountingYear | undefined {
    if (!accountingYears?.length) {
      return undefined;
    }
    const maxAccountingYearIndex: number = Math.max(...accountingYears.map((accYear) => accYear.accountingIndex));
    const lastAccountingYear: AccountingYear | undefined = accountingYears.find(
      (accYear) => accYear.accountingIndex === maxAccountingYearIndex,
    );
    return lastAccountingYear;
  }
}
