import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company, LegalForms } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { UserStateService } from '@dougs/user/shared';
import { CompanyComponentService } from '../../../services/company.component.service';

@Component({
  selector: 'dougs-company-legal-form',
  templateUrl: './company-legal-form.component.html',
  styleUrls: ['./company-legal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    DividerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    NgIf,
    InputDatepickerComponent,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class CompanyLegalFormComponent implements OnInit {
  legalForms!: LegalForms[];
  @Input() companyFields!: Fields;
  @Input() company!: Company;
  isFormDirty = false;

  constructor(
    public readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    public readonly companyComponentService: CompanyComponentService,
  ) {}

  ngOnInit(): void {
    this.legalForms = [...this.company.legalForms];
  }

  getFirstHistoryValue(historyValues: LegalForms[]): LegalForms {
    const value = historyValues.filter((historyValue: LegalForms) => {
      return historyValue.isFirst;
    });
    return value[0];
  }

  addLegalFormPeriod(): void {
    this.legalForms.push({
      value: '',
      isFirst: false,
      startDate: new Date(),
    });
    this.isFormDirty = true;
  }

  removeLegalFormPeriod(legalForm: LegalForms): void {
    const index: number = this.legalForms.indexOf(legalForm);

    if (index >= 0) {
      this.legalForms.splice(index, 1);
    }
    this.isFormDirty = true;
  }

  async onSubmit() {
    const company = { ...this.company, legalForms: this.legalForms };
    this.isFormDirty = false;
    await this.companyStateService.updateCompany(company);
  }

  cancelForm() {
    this.legalForms = [...this.company.legalForms];
    this.isFormDirty = false;
  }

  changeLegalFormValue() {
    this.isFormDirty = true;
  }

  trackByValue(index: number, legalForms: LegalForms): LegalForms {
    return legalForms;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
