import { Injectable } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, from, map, Observable } from 'rxjs';
import { CertifiedOrganism, Company, CompanyCourtAdministration } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { TaxRegime } from '@dougs/core/config-back';
import { FormSettingsService } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';
import { AddressData } from '@dougs/ds';
import { Fields, Option } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { CertifiedOrganismDto, CompanyTaxesService } from '@dougs/settings/dto';

const COMPANY_FIELDS_SCHEMA: (string | Record<string, any>)[] = [
  'legalName',
  'brandName',
  'activity',
  'website',
  'kbis',
  'inpiStatement',
  'legalForm',
  'taxRegime',
  'siren',
  'shareCapital',
  'totalShares',
  'bylaws',
  'registrationDate',
  'activityStartsAt',
  'description',
  'taxRegimeOptionLetter',
  'proofOfAddress',
  'certificateOfFundsSubmission',
  'certificateOfLegalAnnouncementPublication',
  'lockDate',
  'inseePublication',
  'regulatedActivitiesSupportingDocument',
  'assetsAllocation',
  'subscribers',
  'provisionOfPremises',
  'domiciliatorKbis',
  {
    headquarter: [
      'siret',
      'street',
      'city',
      'zipcode',
      'additionalAddress',
      'establishmentOwnerId',
      'isEstablishmentOwnerTemporary',
      'ape',
      'apeDescription',
    ],
  },
  {
    metadata: [
      'isDomiciliated',
      'statusSignedAt',
      'hasCertifiedOrganism',
      'bankNameOfFundsSubmission',
      'fundsSubmissionDate',
      'firstClosingDate',
      'legalPublicationNewspaper',
      'domiciliatorId',
      'domiciliatorSiren',
      'domiciliatorLegalName',
      'isAmbulant',
      'dateOfLegalPublication',
      {
        social: ['corporatePurposeId', 'corporatePurposeModifiedAt', 'corporatePurposeActivityType'],
      },
      { courtsAdministrationService: ['street', 'city', 'zipcode', 'phone', 'webSite', 'name'] },
      { companyTaxesService: ['street', 'city', 'zipcode', 'phone', 'webSite', 'name'] },
      {
        certifiedOrganism: [
          'code',
          'subscriptionNumber',
          'name',
          'street',
          'zipcode',
          'city',
          'agreementNumber',
          'subscriptionDate',
        ],
      },
      { inpiActivity: ['code0', 'code1', 'code2', 'code3'] },
    ],
  },
];

@Injectable()
export class FormCompanyService extends FormSettingsService {
  historyValues: TaxRegime[] = [];
  public selectedCourtAdministration?: CompanyCourtAdministration;
  public selectedTaxesService?: CompanyTaxesService;
  public selectedCertifiedOrganism?: CertifiedOrganism;
  public selectedSocialObject?: Option;
  public formGroupHasBeenDirty = false;
  private company!: Company;

  fullAddressControl: FormControl<{
    street: string | undefined;
    zipcode: string | undefined;
    city: string | undefined;
  }> = new FormControl({ street: '', zipcode: '', city: '' }, { nonNullable: true });

  onSelectAddress$: Observable<void> = this.fullAddressControl.valueChanges.pipe(
    distinctUntilChanged(),
    map((fullAddress: AddressData) => {
      this.onSelectAddress(fullAddress);
    }),
  );

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super(COMPANY_FIELDS_SCHEMA);
  }

  get headquarterFormGroup(): UntypedFormGroup {
    return this.formGroup.get('headquarter') as UntypedFormGroup;
  }

  get metadataFormGroup(): UntypedFormGroup {
    return this.formGroup.get('metadata') as UntypedFormGroup;
  }

  get metadataSocialFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('social') as UntypedFormGroup;
  }

  get courtsAdministrationFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('courtsAdministrationService') as UntypedFormGroup;
  }

  get companyTaxesServiceFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('companyTaxesService') as UntypedFormGroup;
  }

  get certifiedOrganismFormGroup(): UntypedFormGroup {
    return this.metadataFormGroup.get('certifiedOrganism') as UntypedFormGroup;
  }

  public populateForm(company: Company): void {
    this.populateFieldsForm(company);
    this.fullAddressControl.setValue(
      {
        street: company.headquarter?.street,
        zipcode: company.headquarter?.zipcode,
        city: company.headquarter?.city,
      },
      { emitEvent: false },
    );
    this.company = company;
    this.listenFormGroupChanges();
  }

  public listenFormGroupChanges(): void {
    super.listenOnFormGroupChanges((values) => {
      this.formGroupHasBeenDirty = true;
      const currentCompany: Company = mergeObjects(this.company, values);
      return from(this.fieldsStateService.refreshCompanyFields(currentCompany));
    });
  }

  public formatForm(fields: Fields): void {
    this.formatFieldsForm(fields);
    if (
      !fields['headquarter.street']?.isEditable ||
      !fields['headquarter.city']?.isEditable ||
      !fields['headquarter.zipcode']?.isEditable
    ) {
      this.fullAddressControl.disable({ emitEvent: false });
    } else {
      this.fullAddressControl.enable({ emitEvent: false });
    }
  }

  async submitForm(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      await this.updateCompany(this.formGroup.getRawValue());
    }
  }

  onSelectAddress(address: AddressData): void {
    this.headquarterFormGroup.patchValue({
      street: address.street,
      city: address.city,
      zipcode: address.zipcode,
      additionalAddress: address.additionalAddress ?? '',
    });
  }

  async resetForm(company: Company): Promise<void> {
    this.formGroup.markAsPristine();
    this.formGroupHasBeenDirty = false;
    await this.companyStateService.refreshCompany();
    await this.fieldsStateService.refreshCompanyFields(company);
  }

  async updateCompany(values: Partial<Company>): Promise<void> {
    const updatedCompany: Company = mergeObjects(this.company, values);
    this.formGroupHasBeenDirty = false;
    const isUpdated: Company | null = await this.companyStateService.updateCompany(updatedCompany);

    if (!isUpdated) {
      await this.companyStateService.refreshCompany();
    }
  }

  updateCourtAdministrationService(associatedCourtAdministration: CompanyCourtAdministration): void {
    this.courtsAdministrationFormGroup?.setValue({
      city: associatedCourtAdministration?.city ?? null,
      street: associatedCourtAdministration?.street ?? null,
      zipcode: associatedCourtAdministration?.zipcode ?? null,
      webSite: associatedCourtAdministration?.webSite ?? null,
      phone: associatedCourtAdministration?.phone ?? null,
      name: associatedCourtAdministration?.name ?? null,
    });
    this.selectedCourtAdministration = associatedCourtAdministration ?? null;
    this.courtsAdministrationFormGroup?.markAsDirty();
  }

  updateCompanyTaxesService(associatedTaxesService: CompanyTaxesService): void {
    this.companyTaxesServiceFormGroup?.setValue({
      city: associatedTaxesService?.city ?? null,
      street: associatedTaxesService?.street ?? null,
      zipcode: associatedTaxesService?.zipcode ?? null,
      webSite: associatedTaxesService?.webSite ?? null,
      phone: associatedTaxesService?.phone ?? null,
      name: associatedTaxesService?.name ?? null,
    });
    this.selectedTaxesService = associatedTaxesService ?? null;
    this.companyTaxesServiceFormGroup?.markAsDirty();
  }

  updateCertifiedOrganism(associatedCertifiedOrganism: CertifiedOrganismDto): void {
    this.certifiedOrganismFormGroup?.setValue({
      code: associatedCertifiedOrganism?.code ?? null,
      subscriptionNumber: null,
      name: associatedCertifiedOrganism?.name ?? null,
      street: associatedCertifiedOrganism?.street ?? null,
      zipcode: associatedCertifiedOrganism?.zipcode ?? null,
      city: associatedCertifiedOrganism?.city ?? null,
      agreementNumber: associatedCertifiedOrganism?.agreementNumber ?? null,
      subscriptionDate: associatedCertifiedOrganism?.subscriptionDate ?? null,
    });
    this.certifiedOrganismFormGroup?.markAsDirty();
  }

  updateSocialObjectDescription(associatedSocialObject: Option): void {
    this.formGroup.get('description')?.setValue(associatedSocialObject.description ?? '', { emitEvent: false });
    this.formGroup.get('description')?.markAsDirty();
    this.selectedSocialObject = associatedSocialObject;
    this.metadataSocialFormGroup.setValue({
      corporatePurposeId: associatedSocialObject.id,
      corporatePurposeModifiedAt: null,
      corporatePurposeActivityType: associatedSocialObject?.label,
    });
  }

  updateCorporatePurposeModifiedAtForm(isDescriptionDifferentFromTemplate: boolean): void {
    const corporatePurposeModifiedAt: Date | null = isDescriptionDifferentFromTemplate ? new Date() : null;
    this.metadataSocialFormGroup
      .get('corporatePurposeModifiedAt')
      ?.setValue(corporatePurposeModifiedAt, { emitEvent: false });
  }

  setSelectedSocialObject(socialObject: Option | undefined): void {
    this.selectedSocialObject = socialObject;
  }
}
