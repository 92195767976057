import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AddressAutocompleteComponent,
  AddressComponent,
  AddressFieldsEnum,
  DividerComponent,
  LoaderComponent,
  ModalService,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FieldComponent } from '@dougs/fields/ui';
import { EstablishmentsStateService, FileStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyService } from '../../../forms/form-company.service';
import { ProvisionOfPremisesModalComponent } from '../../../modals/provision-of-premises/provision-of-premises-modal.component';
import { CompanyRefreshEstablishmentComponent } from '../company-refresh-establishment/company-refresh-establishment.component';
import { CompanyEstablishmentComponent } from './company-establishments/company-establishment/company-establishment.component';
import { CompanyEstablishmentsComponent } from './company-establishments/company-establishments.component';
import { CompanyHeadquarterOwnerFieldComponent } from './company-headquarter-owner-field.component';

@Component({
  selector: 'dougs-company-headquarter',
  templateUrl: './company-headquarter.component.html',
  styleUrls: ['./company-headquarter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FieldComponent,
    CompanyHeadquarterOwnerFieldComponent,
    PanelInfoComponent,
    DividerComponent,
    NgFor,
    CompanyEstablishmentComponent,
    CompanyRefreshEstablishmentComponent,
    LoaderComponent,
    AsyncPipe,
    TrackByPipe,
    AddressAutocompleteComponent,
    CompanyEstablishmentsComponent,
    AddressComponent,
  ],
})
export class CompanyHeadquarterComponent {
  protected readonly AddressFieldsEnum = AddressFieldsEnum;
  public companyHasIRTaxRegime = false;
  private _company!: Company;

  @Input() companyFields!: Fields;

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly userStateService: UserStateService,
    public readonly fileStateService: FileStateService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    public readonly fieldsStateService: FieldsStateService,
    private readonly modalService: ModalService,
    private readonly cdr: ChangeDetectorRef,
    public readonly companyStateService: CompanyStateService,
  ) {}

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(company) {
    this._company = company;
    this.companyHasIRTaxRegime = !!this.company.taxRegime?.startsWith('ir');
    this.cdr.markForCheck();
  }

  openProvisionOfPremisesModal(e: Event): void {
    e.preventDefault();
    this.modalService.open(ProvisionOfPremisesModalComponent);
  }
}
