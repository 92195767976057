<div
  class="form-layout mb-16"
  *ngIf="
    (companyServicesStateService.services$ | async)?.features.hasSettingsHelp &&
    (company.isCreated || (userStateService.loggedInUser$ | async).isAccountantOrAdmin)
  "
>
  <dougs-panel-info color="secondary">
    Vous avez un nouvel établissement secondaire ?
    <a href="#" (click)="refreshEstablishmentFromSiren($event)">
      Rechercher
      <i
        class="fal ml-4 fa-sync"
        [ngClass]="{
          'fa-spin': isRefreshingEstablishmentFromSiren
        }"
      ></i>
    </a>
  </dougs-panel-info>
</div>
<div class="form-layout mb-16" *ngIf="isCompanyOnRedList">
  <dougs-panel-info type="error">
    Il semble que vous ayez demandé à ne pas diffuser les informations de votre entreprise. Aucune information n'est
    alors disponible.
    <br />
    <b>Veuillez nous contacter pour ajouter ou modifier un établissement.</b>
  </dougs-panel-info>
</div>
<div class="form-layout mb-16" *ngIf="hasntFoundNewEstablishments">
  <dougs-panel-info>
    Aucun nouvel établissement n'a été détecté :
    <ul>
      Si vous avez récemment déclaré un nouvel établissement, sachez que les délais de l'administration peuvent varier.
      Veuillez réessayer dans quelques jours.
    </ul>
    <ul>
      Si vous souhaitez nous confier la déclaration d'un nouvel établissement secondaire,
      <a href="#" class="link-button" (click)="openAddEstablishmentModal($event)">cliquez ici.</a>
    </ul>
  </dougs-panel-info>
</div>
