import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CompanyLegalForm } from '@dougs/company/dto';
import { ConfigBackService } from '@dougs/core/config-back';

@Pipe({
  name: 'propertyType',
  standalone: true,
})
export class PropertyTypePipe implements PipeTransform {
  constructor(private readonly configBackService: ConfigBackService) {}

  transform(legalForm: CompanyLegalForm): Observable<string | undefined | null> {
    return this.configBackService.legalForms$.pipe(
      map((legalForms) => {
        return legalForms?.[legalForm]?.shareType;
      }),
    );
  }
}
