<ng-container *ngIf="companyActivitiesComponentService.refreshCompanyActivities$ | async"></ng-container>
<div class="mb-16">
  <ng-container *ngIf="!companyActivitiesComponentService.isLoading$()">
    <company-activity
      [ngClass]="{ 'mb-8': !last }"
      *ngFor="
        let companyActivity of companyActivityStateService.companyActivities$ | async;
        trackBy: 'id' | trackBy;
        let last = last
      "
      [companyActivity]="companyActivity"
    />

    <div class="form-layout">
      <dougs-button
        (click)="companyActivitiesComponentService.addNewCompanyActivity()"
        [fullWidth]="true"
        class="mt-16"
        color="primary-light"
      >
        <i class="mr-8 color-primary fas fa-plus-circle"></i>
        Ajouter une activité
      </dougs-button>
    </div>
  </ng-container>
  <dougs-loader *ngIf="companyActivitiesComponentService.isLoading$()" />
</div>
