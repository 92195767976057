import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AccountingStats, AccountingSurvey, AccountingSurveyHttpOptions } from '@dougs/accounting-survey/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingSurveyHttpService } from '../http/accounting-survey.http';

interface AccountingSurveyState {
  accountingSurvey: AccountingSurvey;
}

@Injectable({
  providedIn: 'root',
})
export class AccountingSurveyStateService extends StateService<AccountingSurveyState> {
  accountingSurvey$: Observable<AccountingSurvey> = this.select((state) => state.accountingSurvey);

  constructor(
    private readonly accountingSurveyHttpService: AccountingSurveyHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshAccountingSurvey(companyId: number, accountingYearId: number): Promise<AccountingSurvey | null> {
    try {
      // TODO Return the object returned by the HTTP instead of the state.accountingSurvey
      this.setState({
        accountingSurvey: await lastValueFrom(
          this.accountingSurveyHttpService.getAccountingSurvey(companyId, accountingYearId),
        ),
      });
      return this.state.accountingSurvey;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateSurvey(survey: AccountingSurvey, options?: AccountingSurveyHttpOptions): Promise<AccountingSurvey> {
    try {
      const updatedAccountingSurvey: AccountingSurvey = await lastValueFrom(
        this.accountingSurveyHttpService.updateAccountingSurvey(survey, options),
      );
      this.setState({
        accountingSurvey: updatedAccountingSurvey,
      });
      return updatedAccountingSurvey;
    } catch (e) {
      this.logger.error(e);
      // TODO Check if it works and return only this.state.accountingSurvey
      this.setState({
        accountingSurvey: { ...this.state.accountingSurvey },
      });

      return { ...this.state.accountingSurvey };
    }
  }

  async reopenSurvey(
    survey: AccountingSurvey,
    options: {
      reopenForCustomer: boolean;
      dueDate?: Date;
      emailContent?: string;
      applyPenalty?: boolean;
      unlockAccounts?: boolean;
    },
  ): Promise<AccountingSurvey> {
    try {
      const updatedSurvey: AccountingSurvey = await lastValueFrom(
        this.accountingSurveyHttpService.reopenAccountingSurvey(survey, options),
      );
      this.setState({
        accountingSurvey: updatedSurvey,
      });

      return updatedSurvey;
    } catch (e) {
      this.logger.error(e);
      this.setState({
        accountingSurvey: { ...this.state.accountingSurvey },
      });
      return { ...this.state.accountingSurvey };
    }
  }

  async addAttachmentSurvey(
    survey: AccountingSurvey,
    file: File,
    type: string,
    metadata?: { partnerId?: number; type?: string; loanId?: number },
  ): Promise<void> {
    try {
      this.setState({
        accountingSurvey: {
          ...survey,
          attachments: [
            ...survey.attachments,
            await lastValueFrom(
              this.accountingSurveyHttpService.uploadAccountingSurveyAttachment(survey, file, type, metadata),
            ),
          ],
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addMultipleAttachmentSurvey(
    survey: AccountingSurvey,
    files: FileList,
    type: string,
    metadata?: { loanId: number; salesChannelId: number },
  ): Promise<void> {
    try {
      const attachments: Attachment[] = [];

      for (const file of Array.from(files)) {
        attachments.push(
          await lastValueFrom(
            this.accountingSurveyHttpService.uploadAccountingSurveyAttachment(survey, file, type, metadata),
          ),
        );
      }

      this.setState({
        accountingSurvey: {
          ...survey,
          attachments: [...survey.attachments, ...attachments],
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeAttachmentSurvey(survey: AccountingSurvey, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.accountingSurveyHttpService.removeAttachment(survey, attachment));
      this.setState({
        accountingSurvey: {
          ...survey,
          attachments: survey.attachments.filter((attachmentIterated) => attachmentIterated.id !== attachment.id),
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getAccountingStats(survey: AccountingSurvey): Promise<AccountingStats | null> {
    try {
      return await lastValueFrom(this.accountingSurveyHttpService.getAccountingStats(survey));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
