import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyActivityStateService } from '@dougs/company/shared';
import { ButtonComponent, DividerComponent, LoaderComponent, TrackByPipe } from '@dougs/ds';
import { FormCompanyActivityService } from '../../../../forms/form-company-activity.service';
import { CompanyActivitiesComponentService } from '../../../../services/company-activities/company-activities.component.service';
import { CompanyActivityComponent } from './company-activity/company-activity.component';

@Component({
  selector: 'dougs-company-activities',
  templateUrl: './company-activities.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    CompanyActivityComponent,
    DividerComponent,
    ButtonComponent,
    TrackByPipe,
    AsyncPipe,
    NgIf,
    LoaderComponent,
    NgClass,
  ],
  providers: [CompanyActivitiesComponentService, FormCompanyActivityService],
})
export class CompanyActivitiesComponent {
  constructor(
    public readonly companyActivitiesComponentService: CompanyActivitiesComponentService,
    public readonly companyActivityStateService: CompanyActivityStateService,
  ) {}
}
