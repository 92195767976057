import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { CompanySummaryComponent } from '../../components/company/company-summary/company-summary.component';
import { CompanySummaryModalComponentService } from './company-summary-modal.component.service';

@Component({
  selector: 'dougs-company-summary-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalCloseDirective,
    ButtonComponent,
    CompanySummaryComponent,
    LoaderComponent,
  ],
  providers: [CompanySummaryModalComponentService],
  templateUrl: './company-summary-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySummaryModalComponent {
  constructor(public readonly companySummaryModalComponentService: CompanySummaryModalComponentService) {}
}
