<div [formGroup]="formEstablishmentsService.formGroup">
  <ng-container *ngIf="formEstablishmentsService.formGroupChanges$ | async"></ng-container>
  <div class="form-layout mb-16" *ngIf="establishmentFields.establishment['name']?.isAvailable">
    <dougs-field formControlName="name" [field]="establishmentFields.establishment['name']"></dougs-field>
  </div>
  <div class="form-layout mb-16" *ngIf="establishmentFields.establishment['siret']?.isAvailable">
    <dougs-field
      formControlName="siret"
      [field]="establishmentFields.establishment['siret']"
      [canCopy]="true"
    ></dougs-field>
  </div>
  <div class="form-layout mb-16" *ngIf="establishmentFields.establishment['ape']?.isAvailable">
    <dougs-field
      formControlName="ape"
      [field]="establishmentFields.establishment['ape']"
      [canCopy]="true"
    ></dougs-field>
  </div>
  <div class="form-layout mb-16">
    <fieldset>
      <legend>Adresse</legend>
      <div class="mb-16" *ngIf="establishmentFields.establishment['street']?.isAvailable">
        <dougs-field
          formControlName="street"
          [field]="establishmentFields.establishment['street']"
          [canCopy]="true"
        ></dougs-field>
      </div>
      <div
        *ngIf="
          establishmentFields.establishment['city']?.isAvailable ||
          establishmentFields.establishment['zipcode']?.isAvailable
        "
      >
        <div class="form-layout__two-columns-small-first">
          <dougs-field
            formControlName="zipcode"
            [field]="establishmentFields.establishment['zipcode']"
            [canCopy]="true"
          ></dougs-field>
          <dougs-field
            formControlName="city"
            [field]="establishmentFields.establishment['city']"
            [canCopy]="true"
          ></dougs-field>
        </div>
      </div>
    </fieldset>
  </div>
  <div class="form-layout mb-16" *ngIf="establishmentFields.establishment['establishmentOwnerId']?.isAvailable">
    <dougs-field
      formControlName="establishmentOwnerId"
      [field]="establishmentFields.establishment['establishmentOwnerId']"
    ></dougs-field>
  </div>
  <div *ngIf="establishmentFields.establishment['establishmentRentContract']?.isAvailable" class="form-layout mb-16">
    <dougs-field
      (uploadFile)="
        establishmentsStateService.uploadFile(
          establishment,
          establishmentFields.establishment['establishmentRentContract'].input?.fileType,
          $event
        )
      "
      [field]="establishmentFields.establishment['establishmentRentContract']"
      formControlName="establishmentRentContract"
    ></dougs-field>
  </div>
  <div class="form-layout mb-16" *ngIf="establishmentFields.establishment['registrationDate']?.isAvailable">
    <dougs-field
      formControlName="registrationDate"
      [field]="establishmentFields.establishment['registrationDate']"
    ></dougs-field>
  </div>
  <div
    class="form-layout mb-16"
    *ngIf="
      establishmentFields.establishment['endDate']?.isAvailable && (establishmentComponentService.isOpened$ | async)
    "
  >
    <dougs-checkbox
      label="Voulez-vous fermer cet établissement ?"
      appearance="toggle"
      [showYesNo]="true"
      yesText="Oui"
      noText="Non"
      [formControl]="formEstablishmentsService.willCloseEstablishmentControl"
    ></dougs-checkbox>
  </div>
  <div class="form-layout mb-16" *ngIf="establishmentComponentService.shouldShowEndDateField$ | async">
    <dougs-field formControlName="endDate" [field]="establishmentFields.establishment['endDate']"></dougs-field>
  </div>
  <ng-container
    *ngIf="
      (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin &&
      (userStateService.loggedInUser$ | async)?.flags?.includes('canDeleteEstablishment') &&
      establishment?.id
    "
  >
    <dougs-divider class="mb-16"></dougs-divider>
    <p class="tiny color-gray-350">
      Voulez-vous supprimer cet établissement ?
      <a
        href="#"
        class="color-error"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          establishmentComponentService.deleteEstablishment(establishment)
        "
      >
        Supprimer
      </a>
    </p>
  </ng-container>
</div>
