<form (ngSubmit)="form.valid && onSubmit()" #form="ngForm">
  <div class="pb-16">
    <dougs-divider></dougs-divider>
    <div class="legalform-accordion p-16">
      <div class="historic-box">
        <h6 class="title py-8 px-16">Historique</h6>
        <dougs-divider></dougs-divider>
        <dougs-form-field [noMargin]="true" class="p-16 pb-16">
          <label dougsFormFieldLabel>Forme à l'activation</label>
          <dougs-select
            [ngModelOptions]="{ standalone: true }"
            [disabled]="!companyFields['legalForm'].isEditable"
            placeholder="Sélectionner une valeur"
            [(ngModel)]="getFirstHistoryValue(legalForms).value"
            dougsFormFieldControl
            [maxHeight]="300"
          >
            <dougs-select-option
              *ngFor="let legalForm of companyFields['legalForm'].input?.labels.items; trackBy: trackByIndex"
              [value]="legalForm.value"
            >
              {{ legalForm.label }}
            </dougs-select-option>
          </dougs-select>
        </dougs-form-field>
        <div *ngFor="let legalForm of companyComponentService.sortLegalForm(legalForms); trackBy: trackByValue">
          <div *ngIf="!legalForm.isFirst">
            <dougs-divider></dougs-divider>
            <div class="period-line pt-16 px-16 mb-16">
              <div class="form-layout__two-columns inputs">
                <dougs-input-datepicker
                  #input="ngModel"
                  [noMargin]="true"
                  [ngModelOptions]="{ standalone: true }"
                  [required]="true"
                  [disabled]="
                    !(userStateService.loggedInUser$ | async).flags.includes('canAddLegalFormPeriod') &&
                    !(userStateService.loggedInUser$ | async).flags.includes('role:legal')
                  "
                  [(ngModel)]="legalForm.startDate"
                  (ngModelChange)="!input.invalid && changeLegalFormValue()"
                  label="A partir de"
                ></dougs-input-datepicker>
                <dougs-form-field [noMargin]="true">
                  <label dougsFormFieldLabel>Forme de la société</label>
                  <dougs-select
                    [ngModelOptions]="{ standalone: true }"
                    [required]="true"
                    [disabled]="
                      !(userStateService.loggedInUser$ | async).flags.includes('canAddLegalFormPeriod') &&
                      !(userStateService.loggedInUser$ | async).flags.includes('role:legal')
                    "
                    placeholder="Sélectionner une valeur"
                    [(ngModel)]="legalForm.value"
                    dougsFormFieldControl
                    [maxHeight]="300"
                    (ngModelChange)="changeLegalFormValue()"
                  >
                    <dougs-select-option
                      *ngFor="let legalForm of companyFields['legalForm'].input?.labels.items; trackBy: trackByIndex"
                      [value]="legalForm.value"
                    >
                      {{ legalForm.label }}
                    </dougs-select-option>
                  </dougs-select>
                </dougs-form-field>
              </div>
              <dougs-button
                (click)="removeLegalFormPeriod(legalForm)"
                *ngIf="
                  (userStateService.loggedInUser$ | async).flags.includes('canAddLegalFormPeriod') && !company.locked
                "
                class="delete-button"
                color="delete"
              >
                <i class="fal fa-trash"></i>
              </dougs-button>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            (userStateService.loggedInUser$ | async).flags.includes('canAddLegalFormPeriod') ||
            (userStateService.loggedInUser$ | async).flags.includes('role:legal')
          "
        >
          <dougs-divider></dougs-divider>
          <dougs-button color="admin" type="button" (click)="addLegalFormPeriod()" class="p-16">
            <i class="fal fa-plus"></i>
            Ajouter une forme de société
          </dougs-button>
        </div>
      </div>
    </div>
    <div *ngIf="isFormDirty" class="px-16">
      <dougs-divider></dougs-divider>
      <div class="py-16 footer-buttons">
        <dougs-button class="mr-8" color="secondary" (click)="cancelForm()">Annuler</dougs-button>
        <dougs-button type="submit">Enregistrer</dougs-button>
      </div>
    </div>
    <dougs-divider></dougs-divider>
  </div>
</form>
