@if (formCompanyService.onSelectAddress$ | async) {}
<div class="settings-card__content">
  <div class="settings-card__sticky-column-title">
    <h5 class="mb-24">Établissements</h5>
  </div>
  <div [formGroup]="formCompanyService.formGroup">
    <ng-container *ngIf="company && companyFields">
      <ng-container *ngIf="formCompanyService.headquarterFormGroup" formGroupName="headquarter">
        <h5 class="mb-16">Siège social</h5>
        <div class="form-layout mb-16" *ngIf="companyFields['headquarter.siret']?.isAvailable">
          <dougs-field
            formControlName="siret"
            [field]="companyFields['headquarter.siret']"
            [canCopy]="true"
          ></dougs-field>
        </div>
        <div class="form-layout mb-16" *ngIf="companyFields['headquarter.ape']?.isAvailable">
          <dougs-field
            [isAdmin]="!(companyStateService.activeCompany$ | async).isCreated"
            formControlName="ape"
            [field]="companyFields['headquarter.ape']"
            [canCopy]="true"
          ></dougs-field>
        </div>
        <div class="form-layout mb-16">
          <fieldset>
            <legend>Adresse</legend>
            <div
              class="mb-16"
              *ngIf="
                companyFields['headquarter.street']?.isAvailable ||
                companyFields['headquarter.city']?.isAvailable ||
                companyFields['headquarter.zipcode']?.isAvailable
              "
            >
              <dougs-address
                [formControl]="formCompanyService.fullAddressControl"
                [isAccountantOrAdmin]="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin"
                [addressFieldsToDisplay]="[
                  AddressFieldsEnum.STREET,
                  AddressFieldsEnum.ZIPCODE,
                  AddressFieldsEnum.CITY,
                  AddressFieldsEnum.ADDITIONAL_ADDRESS
                ]"
                [activeCompany]="companyStateService.activeCompanyIdChanged$ | async"
              ></dougs-address>
            </div>
            <div class="mb-16" *ngIf="companyFields['headquarter.additionalAddress']?.isAvailable">
              <dougs-field
                formControlName="additionalAddress"
                [field]="companyFields['headquarter.additionalAddress']"
              ></dougs-field>
            </div>
          </fieldset>
        </div>
        <div class="form-layout mb-16" *ngIf="companyFields['headquarter.establishmentOwnerId']?.isAvailable">
          <dougs-company-headquarter-owner-field
            formControlName="establishmentOwnerId"
            [field]="companyFields['headquarter.establishmentOwnerId']"
            [companyFormGroup]="formCompanyService.formGroup"
          ></dougs-company-headquarter-owner-field>
        </div>
      </ng-container>
      <div *ngIf="companyFields['provisionOfPremises']?.isAvailable" class="mb-16">
        <ng-container *ngIf="!companyHasIRTaxRegime && (companyStateService.activeCompany$ | async).isCreated">
          <dougs-panel-info class="mb-16">
            Une partie de vos loyers et de vos charges locatives peut être prise en compte grâce à une convention de
            mise à disposition. Dougs peut vous accompagner dans la rédaction de cette convention !
            <a href="#" (click)="openProvisionOfPremisesModal($event)">Je veux être accompagné(e).</a>
          </dougs-panel-info>
        </ng-container>
        <dougs-field
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['provisionOfPremises'].input?.fileType,
              'provisionOfPremises',
              $event
            )
          "
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'provisionOfPremises',
              companyFields['provisionOfPremises'].input?.allowMultiple
            )
          "
          [field]="companyFields['provisionOfPremises']"
          formControlName="provisionOfPremises"
        ></dougs-field>
      </div>
      <ng-container formGroupName="metadata">
        <div class="form-layout mb-16" *ngIf="companyFields['metadata.isDomiciliated']?.isAvailable">
          <dougs-field
            formControlName="isDomiciliated"
            [field]="companyFields['metadata.isDomiciliated']"
          ></dougs-field>
        </div>
        <div class="form-layout mb-16" *ngIf="companyFields['metadata.domiciliatorSiren']?.isAvailable">
          <dougs-field
            formControlName="domiciliatorSiren"
            class="mb-16"
            [field]="companyFields['metadata.domiciliatorSiren']"
          ></dougs-field>
        </div>
      </ng-container>
      <div class="form-layout mb-16" *ngIf="companyFields['domiciliatorKbis']?.isAvailable">
        <dougs-field
          formControlName="domiciliatorKbis"
          (uploadFile)="
            fileStateService.uploadFiles(
              company,
              companyFields['domiciliatorKbis'].input?.fileType,
              'domiciliatorKbis',
              $event
            )
          "
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              'domiciliatorKbis',
              companyFields['domiciliatorKbis'].input?.allowMultiple
            )
          "
          [field]="companyFields['domiciliatorKbis']"
        ></dougs-field>
      </div>
      <ng-container formGroupName="metadata">
        <div class="form-layout mb-16" *ngIf="companyFields['metadata.domiciliatorLegalName']?.isAvailable">
          <dougs-field
            formControlName="domiciliatorLegalName"
            class="mb-16"
            [field]="companyFields['metadata.domiciliatorLegalName']"
          ></dougs-field>
        </div>
      </ng-container>
      <ng-container *ngIf="companyFields['proofOfAddress']?.isAvailable">
        <dougs-panel-info class="mb-16">
          {{
            formCompanyService.formGroup.get('metadata.isDomiciliated').value
              ? 'Étant domicilié dans une entreprise de domiciliation, seul votre contrat de domiciliation peut être utilisé comme justificatif de domicile.'
              : "Les documents qui peuvent être utilisés comme justificatif de domicile sont la dernière taxe foncière ou tout autre justificatif datant de moins de 3 mois. Par exemple : facture d'électricité, facture de téléphone (fixe uniquement), facture internet, quittance de loyer, bail commercial."
          }}
        </dougs-panel-info>
        <div class="form-layout mb-16">
          <dougs-field
            formControlName="proofOfAddress"
            (uploadFile)="
              fileStateService.uploadFiles(
                company,
                companyFields['proofOfAddress'].input?.fileType,
                'proofOfAddress',
                $event
              )
            "
            (deleteFile)="
              fileStateService.deleteFiles(
                company,
                $event,
                'proofOfAddress',
                companyFields['proofOfAddress'].input?.allowMultiple
              )
            "
            [field]="companyFields['proofOfAddress']"
          ></dougs-field>
        </div>
      </ng-container>
      <dougs-company-establishments />
      <div class="form-layout">
        <dougs-company-refresh-establishment [company]="company"></dougs-company-refresh-establishment>
      </div>
    </ng-container>
    <dougs-loader *ngIf="!company || !companyFields"></dougs-loader>
  </div>
</div>
