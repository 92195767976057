import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  Observable,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Establishment } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ScrollAccordionService } from '@dougs/core/scroll-accordion';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { EstablishmentsStateService } from '@dougs/settings/shared';
import { FormEstablishmentsService } from '../../forms/form-establishments.service';
import { EstablishmentsComponentService } from './establishments.component.service';

@Injectable()
export class EstablishmentComponentService {
  handleLastOpenedEstablishment$: Observable<void> = this.establishmentsComponentService.lastOpenedEstablishment$.pipe(
    distinctUntilChanged(),
    tap((establishment: Establishment | null) => this.handleAccordionOpen(establishment)),
    filter(
      (establishment: Establishment | null): establishment is Establishment =>
        !!establishment && establishment.id === this.establishment.value?.id,
    ),
    tap(() => this.fieldsLoading.set(true)),
    concatMap((establishment: Establishment) =>
      this.fieldsStateService.refreshEstablishmentFields(this.companyStateService.activeCompany.id, establishment),
    ),
    tap(() => this.fieldsLoading.set(false)),
  );

  private readonly fieldsLoading: WritableSignal<boolean> = signal(false);
  fieldsLoading$: Signal<boolean> = this.fieldsLoading.asReadonly();

  handleCloseAccordionOnSubmit$: Observable<void> = this.formEstablishmentService.closeEstablishmentAccordion$.pipe(
    filter((establishment: Establishment) => establishment.id === this.establishment.value?.id),
    map(() => this.setAccordionOpened(false)),
  );

  private readonly establishment: BehaviorSubject<Establishment | null> = new BehaviorSubject<Establishment | null>(
    null,
  );
  establishment$: Observable<Establishment | null> = this.establishment.asObservable();

  private readonly accordionOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  accordionOpened$: Observable<boolean> = this.accordionOpened.asObservable();

  showForm$: Observable<boolean> = combineLatest([
    this.fieldsStateService.establishmentFields$,
    this.establishmentsComponentService.lastOpenedEstablishment$,
    this.accordionOpened$,
  ]).pipe(
    map(
      ([fields, lastOpenedEstablishment, isOpen]) =>
        !!fields && !!lastOpenedEstablishment && isOpen && lastOpenedEstablishment.id === this.establishment.value?.id,
    ),
  );

  isClosed$: Observable<boolean> = this.establishment$.pipe(
    map(
      (establishment) =>
        !!(
          establishment &&
          (!establishment.isActive || !!(establishment.endDate && new Date(establishment.endDate) < new Date()))
        ),
    ),
  );

  isOpened$: Observable<boolean> = this.establishment$.pipe(
    map(
      (establishment) =>
        !!establishment &&
        !!establishment.isActive &&
        (!establishment.endDate || new Date(establishment.endDate) > new Date()),
    ),
  );

  shouldShowEndDateField$: Observable<boolean> = combineLatest([
    this.formEstablishmentService.willCloseEstablishmentControl.valueChanges,
    this.establishment$,
    this.fieldsStateService.establishmentFields$,
  ]).pipe(
    filter(([_, establishment, fields]) => !!establishment && !!fields),
    map(
      ([willCloseEstablishment, establishment, establishmentFields]) =>
        (willCloseEstablishment || !!establishment?.endDate || !establishment?.isActive) &&
        establishmentFields.establishment['endDate']?.isAvailable,
    ),
  );

  isEstablishmentCompleted$: Observable<boolean> = combineLatest([
    this.establishment$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([establishment, company]) =>
        !!(
          establishment?.id &&
          company?.completion?.models?.establishments?.[establishment?.id]?.percent &&
          company?.completion?.models?.establishments?.[establishment?.id]?.percent === 100
        ),
    ),
  );

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly establishmentsComponentService: EstablishmentsComponentService,
    private readonly formEstablishmentService: FormEstablishmentsService,
    private readonly scrollAccordionService: ScrollAccordionService,
    private readonly establishmentsStateService: EstablishmentsStateService,
  ) {}

  setAccordionOpened(opened: boolean): void {
    if (opened) {
      this.establishmentsComponentService.setLastOpenedEstablishment(this.establishment.value);
    }
    this.accordionOpened.next(opened);
  }

  setEstablishment(establishment: Establishment): void {
    this.establishment.next(establishment);
  }

  async deleteEstablishment(establishment: Partial<Establishment>): Promise<void> {
    const confirmDelete: boolean = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: 'Êtes-vous bien certain de vouloir supprimer cet établissement secondaire ?',
            yesText: "Oui, supprimer l'établissement",
            noText: 'Non, ne rien faire',
          },
        })
        .afterClosed$.pipe(map((result) => !!result.data)),
    );

    if (confirmDelete && establishment.companyId) {
      await this.establishmentsStateService.deleteEstablishment(establishment.companyId, establishment);
    }
  }

  cancelEstablishment(): void {
    this.formEstablishmentService.formGroup.markAsUntouched();
    this.formEstablishmentService.formGroup.markAsPristine();
    this.setAccordionOpened(false);
    this.scrollAccordionService.scrollToId('establishment-card-content');
  }

  private handleAccordionOpen(establishment: Establishment | null): void {
    if (this.establishment.value?.id !== establishment?.id) {
      this.setAccordionOpened(false);
    } else if (!this.accordionOpened.value) {
      this.setAccordionOpened(true);
    }
  }
}
