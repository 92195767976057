<div dougsModalTitle>
  <h6>Informations de l'entreprise sur l'exercice en cours</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="large">
  <ng-container *ngIf="companySummaryModalComponentService.refreshAccountingYears$ | async"></ng-container>
  <ng-container
    *ngIf="companySummaryModalComponentService.lastAccountingYear$ | async as lastAccountingYear; else loader"
  >
    <dougs-company-summary
      [accountingYear]="lastAccountingYear"
      [isAccountant]="true"
      [disableBorder]="true"
    ></dougs-company-summary>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose color="secondary">Fermer</dougs-button>
</div>
