import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { ConfigBackService } from '@dougs/core/config-back';
import { ControlFormFieldDirective, LabelFormFieldDirective, SelectComponent, SelectOptionComponent } from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { CompanyTaxesService } from '@dougs/settings/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyService } from '../../../forms/form-company.service';

@Component({
  selector: 'dougs-company-taxes-service',
  templateUrl: './company-taxes-service.component.html',
  styleUrls: ['./company-taxes-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    FieldComponent,
    AsyncPipe,
  ],
})
export class CompanyTaxesServiceComponent {
  @Input() company!: Company;
  @Input() companyFields!: Fields;

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly userStateService: UserStateService,
    public readonly configBackService: ConfigBackService,
  ) {}

  trackByName(index: number, item: CompanyTaxesService): string {
    return item.name;
  }
}
