import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Establishment } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AccordionComponent,
  AccordionDescriptionDirective,
  AccordionFooterDirective,
  AccordionTitleDirective,
  ButtonComponent,
  DougsDatePipe,
  PillComponent,
} from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { EstablishmentsStateService, FileStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormEstablishmentsService } from '../../../../../forms/form-establishments.service';
import { EstablishmentComponentService } from '../../../../../services/establishments/establishment.component.service';
import { CompanyEstablishmentFormComponent } from './company-establishment-form/company-establishment-form.component';

@Component({
  selector: 'dougs-company-establishment',
  templateUrl: './company-establishment.component.html',
  styleUrls: ['./company-establishment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    PillComponent,
    CompanyEstablishmentFormComponent,
    DougsDatePipe,
    AsyncPipe,
    ReactiveFormsModule,
    AccordionComponent,
    AccordionTitleDirective,
    AccordionDescriptionDirective,
    NgForOf,
    AccordionFooterDirective,
    ButtonComponent,
  ],
  providers: [EstablishmentComponentService],
})
export class CompanyEstablishmentComponent {
  @Input()
  set establishment(establishment: Establishment) {
    this.establishmentComponentService.setEstablishment(establishment);
  }

  constructor(
    public readonly formEstablishmentsService: FormEstablishmentsService,
    public readonly userStateService: UserStateService,
    public readonly fileStateService: FileStateService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    public readonly fieldsStateService: FieldsStateService,
    public readonly establishmentComponentService: EstablishmentComponentService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  protected readonly Array = Array;
}
