import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { combineLatest, lastValueFrom, map, startWith, Subscription } from 'rxjs';
import {
  AvatarComponent,
  BlankStateComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  OverlayCloseEvent,
  PanelInfoComponent,
  PartnerImageUrlPipe,
  PrefixFormFieldDirective,
} from '@dougs/ds';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';

@Component({
  selector: 'dougs-add-associate-modal',
  templateUrl: './add-associate-modal.component.html',
  styleUrls: ['./add-associate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    PrefixFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    ReactiveFormsModule,
    NgFor,
    AvatarComponent,
    NgIf,
    BlankStateComponent,
    PanelInfoComponent,
    AsyncPipe,
    PartnerImageUrlPipe,
  ],
})
export class AddAssociateModalComponent {
  searchSubscription!: Subscription;
  searchControl = new UntypedFormControl('');

  itemSearch$ = combineLatest([
    this.partnerStateService.allCurrentPartnersButAssociates$,
    this.searchControl.valueChanges.pipe(startWith(null)),
  ]).pipe(
    map(([allCurrentPartnersButAssociates, searchValue]) => {
      if (!searchValue || !searchValue.length) {
        return allCurrentPartnersButAssociates;
      }

      return allCurrentPartnersButAssociates.filter((partner: Partner) => {
        const formatedFullName = partner.fullName.toLocaleLowerCase().replace(' ', '');
        const formatedSearch = searchValue.toLocaleLowerCase().replace(' ', '');

        return formatedFullName.includes(formatedSearch);
      });
    }),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      companyId: number;
    },
    private readonly modalRef: ModalRef,
    private readonly partnerStateService: PartnerStateService,
    private readonly modalService: ModalService,
  ) {}

  async openAssociateModal(partner: Partner, companyId: number, shouldScrollToRoleSection = false): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { PartnerModalComponent } = await import('@dougs/partners/ui');
    const { type: modalStatus } = await lastValueFrom(
      this.modalService.open(PartnerModalComponent, {
        data: {
          partnerId: partner.id,
          companyId,
          ...(shouldScrollToRoleSection ? { sectionId: 'rolesSection' } : undefined),
          containerToOpen: 'isAssociate',
          roleToAdd: 'isAssociate',
        },
      }).afterClosed$,
    );

    if (modalStatus === 'close') {
      this.modalRef.close();
    }
  }
  async openCreateAssociateModal(companyId: number): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AddPartnerModalComponent } = await import('@dougs/partners/ui');
    const modalResult: OverlayCloseEvent<unknown> = await lastValueFrom(
      this.modalService.open(AddPartnerModalComponent, {
        data: {
          companyId,
        },
      }).afterClosed$,
    );

    const closeModal = !!modalResult?.data;
    if (closeModal) {
      const createdPartner: Partner = modalResult.data as Partner;
      if (createdPartner) {
        await this.openAssociateModal(createdPartner, companyId);
      }
    }
  }

  trackById(index: number, partner: Partner): number {
    return partner.id;
  }
}
