import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AvatarMessageComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  ModalFooterDirective,
  RadioComponent,
  RadioGroupComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { ProvisionOfPremisesService } from '../../../services/provision-of-premises.service';
import { RequestSentModal } from '../../request-sent-modal/request-sent-modal.component';

@Component({
  selector: 'provision-of-premises-modal-form',
  templateUrl: './provision-of-premises-modal-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProvisionOfPremisesService],
  standalone: true,
  imports: [
    NgIf,
    AvatarMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    RadioGroupComponent,
    RadioComponent,
    ControlFormFieldDirective,
    SuffixFormFieldDirective,
    InputDatepickerComponent,
    ModalFooterDirective,
    ButtonComponent,
    RequestSentModal,
  ],
})
export class ProvisionOfPremisesModalFormComponent {
  requestSent = false;

  constructor(
    public readonly provisionOfPremisesService: ProvisionOfPremisesService,
    private readonly cdr: ChangeDetectorRef,
  ) {}
  async onSubmit(): Promise<void> {
    const result = await this.provisionOfPremisesService.submitModal();
    if (result) {
      this.requestSent = true;
      this.cdr.markForCheck();
    }
  }
}
