<ng-container *ngIf="establishmentsComponentService.refreshEstablishments$ | async"></ng-container>
<dougs-loader class="mt-24" *ngIf="establishmentsComponentService.isLoading$()" />
<div
  class="mt-24"
  *ngIf="
    !establishmentsComponentService.isLoading$() && (establishmentsStateService.establishments$ | async)?.length > 1
  "
>
  <dougs-divider></dougs-divider>
  <h5 class="mt-24 mb-8" id="establishmentsMissingFields">Établissements secondaires</h5>
  <ng-container
    *ngFor="
      let establishment of establishmentsStateService.establishments$ | async;
      trackBy: 'id' | trackBy;
      let last = last
    "
  >
    <dougs-company-establishment
      *ngIf="establishment.id !== (companyStateService.activeCompany$ | async).headquarter.id"
      [ngClass]="{ 'mb-8': !last }"
      [establishment]="establishment"
    ></dougs-company-establishment>
  </ng-container>
</div>
