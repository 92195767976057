import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanySummary } from '@dougs/accounting-survey/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { CompanySummaryHttpService } from '../http/company-summary.http';

interface CompanySummaryState {
  companySummary: CompanySummary;
}

// TODO Put it in the company domain in shared
@Injectable({
  providedIn: 'root',
})
export class CompanySummaryStateService extends StateService<CompanySummaryState> {
  constructor(
    private readonly companySummaryHttpService: CompanySummaryHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshCompanySummary(accountingYear: AccountingYear, isAccountant = false): Promise<CompanySummary | null> {
    try {
      return await lastValueFrom(this.companySummaryHttpService.getCompanySummary(accountingYear, isAccountant));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
