import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, ModalCloseDirective, ModalContentDirective, ModalFooterDirective } from '@dougs/ds';

@Component({
  selector: 'request-sent-modal',
  templateUrl: './request-sent-modal.component.html',
  styleUrls: ['./request-sent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalContentDirective, ModalFooterDirective, ButtonComponent, ModalCloseDirective],
})
export class RequestSentModal {}
