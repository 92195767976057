import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { CertifiedOrganism, ConfigBackService } from '@dougs/core/config-back';
import {
  ControlFormFieldDirective,
  DividerComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Fields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { PartnerStateService } from '@dougs/partners/shared';
import { FileStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyService } from '../../../forms/form-company.service';
import { CompanyComponentService } from '../../../services/company.component.service';
import { AssociateListComponent } from './associate-list/associate-list.component';

@Component({
  selector: 'dougs-company-legal',
  templateUrl: './company-legal.component.html',
  styleUrls: ['./company-legal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FieldComponent,
    DividerComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    SelectOptionComponent,
    NgFor,
    AssociateListComponent,
    LoaderComponent,
    AsyncPipe,
  ],
  providers: [CompanyComponentService],
})
export class CompanyLegalComponent {
  certifiedOrganism$!: Observable<CertifiedOrganism[] | undefined>;
  @Input() companyFields!: Fields;
  private _company!: Company;

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly companyComponentService: CompanyComponentService,
    public readonly fileStateService: FileStateService,
    public readonly userStateService: UserStateService,
    public readonly configBackService: ConfigBackService,
    public readonly partnerStateService: PartnerStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(company: Company) {
    this._company = company;
    this.getCertifiedOrganism();
  }

  getCertifiedOrganism(): void {
    switch (this.company.taxRegime) {
      case 'ir_2031':
        this.certifiedOrganism$ = this.configBackService.certifiedOrganismsCga$;
        break;
      case 'ir_2035':
        this.certifiedOrganism$ = this.configBackService.certifiedOrganismsAga$;
        break;
      default:
        this.certifiedOrganism$ = this.configBackService.certifiedOrganisms$;
        break;
    }
  }

  trackByCode(index: number, item: CertifiedOrganism): string {
    return item.code;
  }
}
