<div class="settings-card__sticky-column-title">
  <ng-container *ngIf="queueNgModelChange$ | async"></ng-container>
  <h5 class="mb-8">Informations de l'entreprise</h5>
  <ng-container *ngIf="userStateService.loggedInUser?.isAccountantOrAdmin">
    <div class="mb-8">
      <dougs-checkbox
        appearance="toggle"
        [showYesNo]="true"
        yesText="Paramètres verrouillés"
        noText="Paramètres déverrouillés"
        [isAdmin]="true"
        (ngModelChange)="onUpdateCompanyLocked($event)"
        [ngModel]="company?.locked"
      ></dougs-checkbox>
    </div>
    <div class="mb-8" *ngIf="userStateService.loggedInUser?.flags?.includes('canUpdatePrefilledCompanySettings')">
      <dougs-checkbox
        appearance="toggle"
        [showYesNo]="true"
        yesText="Paramètres préremplis"
        noText="Paramètres non préremplis"
        [isAdmin]="true"
        (ngModelChange)="onUpdateCompanyPrefilled($event)"
        [ngModel]="company?.metadata?.prefilled"
      ></dougs-checkbox>
    </div>
  </ng-container>
  <dougs-panel-info *ngIf="(companyServicesStateService.services$ | async)?.features.hasSettingsHelp">
    <i class="fal fa-lightbulb-on mb-8 color-primary fa-3x"></i>
    <div>
      Vous avez un doute ? Munissez-vous de votre Kbis ou de votre avis Siren pour remplir plus facilement vos
      paramètres. Plus d'informations pour
      <a
        rel="noopener"
        class="bold"
        href="https://www.dougs.fr/blog/comment-obtenir-gratuitement-son-kbis-en-ligne/"
        target="_blank"
      >
        obtenir gratuitement votre Kbis
      </a>
      et
      <a rel="noopener" class="bold" href="https://avis-situation-sirene.insee.fr/" target="_blank">votre avis SIREN</a>
    </div>
  </dougs-panel-info>
</div>
