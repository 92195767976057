import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyActivity } from '@dougs/company/dto';
import { ButtonComponent, DividerComponent } from '@dougs/ds';
import { AllFields } from '@dougs/fields/dto';
import { FieldComponent } from '@dougs/fields/ui';
import { FormCompanyActivityService } from '../../../../../forms/form-company-activity.service';
import { CompanyActivityComponentService } from '../../../../../services/company-activities/company-activity.component.service';

@Component({
  selector: 'dougs-company-activity-form',
  templateUrl: './company-activity-form.component.html',
  styleUrls: ['./company-activity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, FieldComponent, DividerComponent, ButtonComponent, AsyncPipe],
})
export class CompanyActivityFormComponent {
  constructor(
    public readonly formCompanyActivityService: FormCompanyActivityService,
    public readonly companyActivityComponentService: CompanyActivityComponentService,
  ) {}

  private _companyActivityFields!: AllFields;

  get companyActivityFields(): AllFields {
    return this._companyActivityFields;
  }

  @Input()
  set companyActivityFields(companyActivityFields: AllFields) {
    this._companyActivityFields = companyActivityFields;
    if (this._companyActivityFields) {
      this.formCompanyActivityService.formatForm(companyActivityFields);
    }
  }

  private _companyActivity!: CompanyActivity;

  get companyActivity() {
    return this._companyActivity;
  }

  @Input()
  set companyActivity(companyActivity: CompanyActivity) {
    if (companyActivity) {
      this.formCompanyActivityService.populateCompanyActivityForm(companyActivity);
    }
    this._companyActivity = companyActivity;
  }
}
