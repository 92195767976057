import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';

@Injectable()
export class ProvisionOfPremisesService {
  constructor(private readonly companyStateService: CompanyStateService) {}

  form: FormGroup = new FormGroup({
    establishementOwner: new FormControl('', [Validators.required]),
    establishmentTotalArea: new FormControl('', [Validators.required]),
    establishmentAreaForRent: new FormControl('', [Validators.required]),
    establishmentRentAmount: new FormControl('', [Validators.required]),
    establishmentMonthlyFees: new FormControl('', [Validators.required]),
    establishmentRentStartDate: new FormControl('', [Validators.required]),
  });

  get establishementOwner(): AbstractControl | null {
    return this.form.get('establishementOwner');
  }
  get establishmentTotalArea(): AbstractControl | null {
    return this.form.get('establishmentTotalArea');
  }
  get establishmentAreaForRent(): AbstractControl | null {
    return this.form.get('establishmentAreaForRent');
  }
  get establishmentRentAmount(): AbstractControl | null {
    return this.form.get('establishmentRentAmount');
  }
  get establishmentMonthlyFees(): AbstractControl | null {
    return this.form.get('establishmentMonthlyFees');
  }
  get establishmentRentStartDate(): AbstractControl | null {
    return this.form.get('establishmentRentStartDate');
  }
  requestPending = false;
  async submitModal() {
    this.form.markAllAsTouched();
    if (this.form.valid && this.requestPending === false) {
      this.requestPending = true;
      const requestResult = await this.companyStateService.createProvisionOfPremisesTask(this.form.value);
      this.requestPending = false;
      return requestResult;
    }
    return null;
  }
}
