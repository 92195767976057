import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { from, Observable, Subject } from 'rxjs';
import { CompanyActivity } from '@dougs/company/dto';
import { CompanyActivityStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { FormSettingsService } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';
import { AllFields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';

const COMPANY_ACTIVITY_SCHEMA: (Record<string, string[]> | string)[] = [
  'isMainActivity',
  'activity',
  'isJqpaNeeded',
  'attachments',
  { inseeActivityCode: ['code0', 'code1', 'code2', 'code3'] },
];

@Injectable()
export class FormCompanyActivityService extends FormSettingsService {
  companyActivity!: CompanyActivity;

  private readonly closeCompanyActivityAccordion: Subject<CompanyActivity> = new Subject<CompanyActivity>();
  closeCompanyActivityAccordion$: Observable<CompanyActivity> = this.closeCompanyActivityAccordion.asObservable();

  constructor(
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyActivityStateService: CompanyActivityStateService,
  ) {
    super(COMPANY_ACTIVITY_SCHEMA);
  }

  updateInseeActivityServiceCode(select: string): void {
    switch (select) {
      case 'code0':
        this.formGroup.get('inseeActivityCode')?.patchValue(
          {
            code1: null,
            code2: null,
            code3: null,
          },
          { emitEvent: false },
        );
        break;
      case 'code1':
        this.formGroup.get('inseeActivityCode')?.patchValue({ code2: null, code3: null }, { emitEvent: false });
        break;
      case 'code2':
        this.formGroup.get('inseeActivityCode')?.patchValue({ code3: null }, { emitEvent: false });
    }
  }

  async submitForm(): Promise<CompanyActivity | null> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      const mergedCompanyActivity: CompanyActivity = mergeObjects(this.companyActivity, this.formGroup.value);
      const inseeActivityCodeControl: AbstractControl | null = this.formGroup.get('inseeActivityCode');
      if (inseeActivityCodeControl !== null) {
        mergedCompanyActivity.inseeActivityCode = inseeActivityCodeControl.value;
      }

      const updatedCompanyActivity: CompanyActivity | null =
        await this.companyActivityStateService.updateCompanyActivity(mergedCompanyActivity);

      if (updatedCompanyActivity) {
        this.closeCompanyActivityAccordion.next(updatedCompanyActivity);
      }

      return updatedCompanyActivity;
    }

    return null;
  }

  public formatForm(fields: AllFields): void {
    this.formatFieldsForm(fields.companyActivity);
  }

  public listenFormGroupChanges(): void {
    this.listenOnFormGroupChanges((values) =>
      from(this.fieldsStateService.refreshCompanyActivityFields(mergeObjects(this.companyActivity, values))),
    );
  }

  populateCompanyActivityForm(companyActivity: CompanyActivity): void {
    this.populateFieldsForm(companyActivity);
    this.companyActivity = companyActivity;
    this.listenFormGroupChanges();
  }

  async uploadFiles(companyActivity: CompanyActivity, files: FileList): Promise<void> {
    await this.companyActivityStateService.addCompanyActivityAttachments(companyActivity, files);
    await this.fieldsStateService.refreshCompanyActivityFields(companyActivity);
  }

  async deleteFile(companyActivity: CompanyActivity, attachment: Attachment): Promise<void> {
    await this.companyActivityStateService.deleteCompanyActivityAttachments(companyActivity, attachment);
    await this.fieldsStateService.refreshCompanyActivityFields(companyActivity);
  }
}
