import { Injectable } from '@angular/core';
import { concatMap, from, Observable, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanySummary, CompanySummaryVehicleData } from '@dougs/accounting-survey/dto';
import { CompanySummaryStateService } from '@dougs/accounting-survey/shared';
import { AccountingYear } from '@dougs/accounting-years/dto';

@Injectable()
export class CompanySummaryComponentService {
  private readonly accountingYearSubject: ReplaySubject<AccountingYear> = new ReplaySubject<AccountingYear>(1);
  public accountingYear$: Observable<AccountingYear> = this.accountingYearSubject.asObservable();

  private readonly companySummarySubject: ReplaySubject<CompanySummary | null> =
    new ReplaySubject<CompanySummary | null>(1);
  public companySummary$: Observable<CompanySummary | null> = this.companySummarySubject.asObservable();

  private isAccountant = false;

  refreshCompanySummary$: Observable<CompanySummary | null> = this.accountingYear$.pipe(
    concatMap((accountingYear) => {
      if (!accountingYear) {
        return of(null);
      }
      return from(this.companySummaryStateService.refreshCompanySummary(accountingYear, this.isAccountant));
    }),
    tap((companySummary: CompanySummary | null) => this.companySummarySubject.next(companySummary)),
  );

  constructor(private readonly companySummaryStateService: CompanySummaryStateService) {}

  public setAccountingYear(accountingYear: AccountingYear): void {
    this.accountingYearSubject.next(accountingYear);
  }

  public setIsAccountant(isAccountant: boolean): void {
    this.isAccountant = isAccountant;
  }

  getVehicleIcon(vehicle: CompanySummaryVehicleData): string {
    switch (vehicle.type) {
      case 'motorcycle':
        return 'fa-motorcycle';
      case 'utility':
        return 'fa-truck';
      default:
        return 'fa-car-side';
    }
  }
}
