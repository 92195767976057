import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { concatMap, debounceTime, distinctUntilChanged, Observable, Subject, tap } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AutofocusDirective,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';

@Component({
  selector: 'dougs-search-siren',
  standalone: true,
  templateUrl: './search-siren.component.html',
  styleUrls: ['./search-siren.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchSirenComponent),
    },
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormFieldComponent,
    ReactiveFormsModule,
    SelectComponent,
    SelectOptionComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    LoaderComponent,
    FormFieldComponent,
    ErrorFormFieldDirective,
    AutofocusDirective,
  ],
})
export class SearchSirenComponent implements ControlValueAccessor {
  showSearch = false;
  isLoadingSearch: Subject<boolean> = new Subject<boolean>();
  isLoadingSearch$: Observable<boolean> = this.isLoadingSearch.asObservable();

  @Input() selectedCompanyError!: boolean;
  @Input() hasAutofocus = false;
  @Input() isPositionAbsolute = false;
  @Input() clickToSearch = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() noMargin = true;
  @Input() label = 'Entreprise';
  @Input() showLabel = true;

  searchSirenControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  searchSiren$: Observable<Company[]> = this.searchSirenControl.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(500),
    tap(() => this.isLoadingSearch.next(true)),
    concatMap((search) => this.companyStateService.searchCompanyData(search)),
    tap(() => this.isLoadingSearch.next(false)),
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly companyStateService: CompanyStateService,
  ) {}

  private _company!: Company | null;

  setCompany(company: Company): void {
    this.company = company;
    this.showSearch = false;
    this.selectedCompanyError = false;
  }

  get company(): Company | null {
    return this._company;
  }

  set company(company: Company | null) {
    this._company = company;
    this.onChange(company);
  }

  onChange!: (company: Company | null) => void;
  onTouched!: () => void;

  writeValue(company: Company | null): void {
    this._company = company;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (company: Company | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  trackBySiren(_: number, item: Company): string {
    return item.siren;
  }
}
