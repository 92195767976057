import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, CompanyActivity, LegalForms } from '@dougs/company/dto';
import { CompanyActivityStateService, CompanyStateService } from '@dougs/company/shared';
import { TaxRegime } from '@dougs/core/config-back';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  CopyToClipboardDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  ModalService,
  PanelInfoComponent,
  PrefixFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
  SelectOptionGroupComponent,
} from '@dougs/ds';
import { AllFields, Fields, Option } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FieldComponent } from '@dougs/fields/ui';
import { FileStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { FormCompanyActivityService } from '../../../forms/form-company-activity.service';
import { FormCompanyService } from '../../../forms/form-company.service';
import { HistoryValueModalTaxRegimeComponent } from '../../../modals/history-value-modal-tax-regime/history-value-modal-tax-regime.component';
import { CompanyComponentService } from '../../../services/company.component.service';
import { CompanyLegalFormComponent } from '../company-legal-form/company-legal-form.component';
import { CompanyActivitiesComponent } from './company-activity/company-activities.component';

@Component({
  selector: 'dougs-company-global',
  templateUrl: './company-global.component.html',
  styleUrls: ['./company-global.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FieldComponent,
    CompanyLegalFormComponent,
    PanelInfoComponent,
    ButtonComponent,
    LabelFormFieldDirective,
    FileInputComponent,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionGroupComponent,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    CompanyActivitiesComponent,
    AsyncPipe,
    PrefixFormFieldDirective,
    CopyToClipboardDirective,
    FormFieldComponent,
  ],
  providers: [FormCompanyActivityService, CompanyComponentService],
})
export class CompanyGlobalComponent implements OnInit {
  showAlertRegulations = false;
  regulations?: string;
  @ViewChild('socialObjectIdSelect') socialObjectIdSelect?: NgModel;
  public socialObjectOptionsGroupedBy?: { [group: string]: Option[] };
  public socialObjectOptionsConcat?: Option[];
  public socialObjectOptionsGroups?: string[];
  public showLegalFormHistoricContent = false;
  public legalFormChanges?: string;
  private readonly _companyActivity!: CompanyActivity;

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly companyComponentService: CompanyComponentService,
    public readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly cdr: ChangeDetectorRef,
    public readonly fieldsStateService: FieldsStateService,
    public readonly formCompanyActivityService: FormCompanyActivityService,
    public readonly companyStateService: CompanyStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    public readonly companyActivityStateService: CompanyActivityStateService,
    public readonly fileStateService: FileStateService,
  ) {}

  shouldShowLegalFormDetails$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  get companyActivity(): CompanyActivity {
    return this._companyActivity;
  }

  private _company!: Company;

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(company: Company) {
    this._company = company;
    if (this.company) {
      if (!this.formCompanyService.selectedSocialObject?.id && this.socialObjectIdSelect?.value) {
        this.socialObjectIdSelect?.reset();
      }
      if (this.companyFields) {
        this.setSelectedSocialObject();
        this.setRegulationsAndAlert();
      }
      this.setLegalFormChange();
    }
  }

  private _companyActivityFields!: AllFields;

  get companyActivityFields(): AllFields {
    return this._companyActivityFields;
  }

  @Input()
  set companyActivityFields(companyActivityFields) {
    this._companyActivityFields = companyActivityFields;
    if (this.companyActivityFields) {
      this.formCompanyActivityService.formatForm(this.companyActivityFields);
    }
  }

  private _companyFields!: Fields;

  get companyFields(): Fields {
    return this._companyFields;
  }

  @Input()
  set companyFields(companyFields) {
    this._companyFields = companyFields;
    if (this.companyFields) {
      this.formCompanyService.formatForm(this.companyFields);
      this.formatSocialObjectOptions();
    }
  }

  ngOnInit(): void {
    this.setSelectedSocialObject();
    this.setRegulationsAndAlert();
  }

  async openHistoryValueModal(): Promise<void> {
    const historyValues: TaxRegime[] | null | undefined = (
      await lastValueFrom(
        this.modalService.open<TaxRegime[]>(HistoryValueModalTaxRegimeComponent, {
          data: {
            company: this.company,
            taxRegimeField: this.companyFields['taxRegime'],
          },
        }).afterClosed$,
      )
    ).data;

    if (historyValues) {
      this.formCompanyService.historyValues = historyValues as TaxRegime[];
      this.selectCurrentTaxRegime();
      this.formCompanyService.formGroupHasBeenDirty = true;
      this.cdr.markForCheck();
    }
  }

  selectCurrentTaxRegime(): void {
    const currentTaxRegime: TaxRegime | undefined = this.formCompanyService.historyValues.find(
      (taxRegime) => new Date(taxRegime.startDate) <= new Date(),
    );

    if (currentTaxRegime) {
      this.company.taxRegime = currentTaxRegime.value;

      this.formCompanyService.populateForm(this.company);
    }
  }

  onSocialObjectChange(e?: Option): void {
    this.regulations = e?.regulations;
    this.showAlertRegulations = false;
    if (e) {
      this.formCompanyService.updateSocialObjectDescription(e);
    }
  }

  onDescriptionChange(socialDescription: string): void {
    if (this.formCompanyService?.selectedSocialObject) {
      const selectedSocialOption: Option = this.formCompanyService?.selectedSocialObject;
      const selectedSocialOptionDescription: string | undefined = selectedSocialOption?.description;
      const isDescriptionDifferentFromTemplate: boolean = selectedSocialOptionDescription !== socialDescription;
      this.formCompanyService.updateCorporatePurposeModifiedAtForm(isDescriptionDifferentFromTemplate);
      this.showAlertRegulations = isDescriptionDifferentFromTemplate;
    }
  }

  trackById(
    index: number,
    item: { groupLabel: string; id: number; value: number; description: string; label: string },
  ): number {
    return item.id;
  }

  setLegalFormChange(): void {
    const sortedLegalForms: LegalForms[] = this.companyComponentService.sortLegalForm(this.company.legalForms);
    if (sortedLegalForms.length > 1) {
      const changeDate: string = sortedLegalForms[sortedLegalForms.length - 1].startDate.toString();
      this.legalFormChanges = `${sortedLegalForms[sortedLegalForms.length - 1].value.toUpperCase()} depuis le ${format(
        new Date(changeDate),
        'dd/MM/yyyy',
      )}, ${sortedLegalForms[sortedLegalForms.length - 2].value.toUpperCase()} précédemment`;
    }
  }

  private formatSocialObjectOptions(): void {
    if (this.companyFields['description']?.input?.items) {
      this.socialObjectOptionsGroupedBy = _.groupBy(this.companyFields['description'].input.items, 'groupLabel');
      this.socialObjectOptionsGroups = Object.keys(this.socialObjectOptionsGroupedBy);
      this.socialObjectOptionsConcat = Object.values(this.socialObjectOptionsGroupedBy)?.reduce(
        (acc, cv) => acc.concat(cv),
        [],
      );
    }
  }

  private getSelectedSocialOptionById(corporatePurposeId: number): Option | undefined {
    return this.socialObjectOptionsConcat?.find((socialOption: Option) => socialOption.id === corporatePurposeId);
  }

  private setSelectedSocialObject(): void {
    const corporatePurposeId: number = this.company.metadata?.social?.corporatePurposeId;
    const selectedSocialOption: Option | undefined = this.getSelectedSocialOptionById(corporatePurposeId);
    this.formCompanyService.setSelectedSocialObject(selectedSocialOption);
  }

  private setRegulationsAndAlert(): void {
    const selectedSocialOption: Option | undefined = this.formCompanyService?.selectedSocialObject;
    if (selectedSocialOption) {
      this.showAlertRegulations = this.company.description !== selectedSocialOption.description;
      this.regulations = selectedSocialOption.regulations;
    } else {
      this.showAlertRegulations = false;
      this.regulations = undefined;
    }
  }
}
