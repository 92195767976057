<div dougsModalContent class="request-sent">
  <img src="images/illustrations/request-sent.png" />
  <h5 class="mt-16 mb-16">Demande envoyée</h5>
  <p>
    Nous avons bien pris en compte votre demande.
    <br />
    Surveillez vos notifications, nous allons vous recontacter rapidement avec un devis personnalisé.
  </p>
</div>
<div dougsModalFooter>
  <dougs-button class="mt-16" color="primary" dougsModalClose>OK</dougs-button>
</div>
