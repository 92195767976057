import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { LottieComponent } from 'ngx-lottie';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { FieldComponent, MissingFieldsBadgeService } from '@dougs/fields/ui';

@Component({
  selector: 'dougs-company-headquarter-owner-field',
  templateUrl: 'company-headquarter-owner-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MissingFieldsBadgeService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CompanyHeadquarterOwnerFieldComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    FormsModule,
    NgFor,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    NgTemplateOutlet,
    AsyncPipe,
    LottieComponent,
  ],
})
export class CompanyHeadquarterOwnerFieldComponent extends FieldComponent {
  private readonly _tempPrefix = 'dt:';

  private _companyFormGroup!: UntypedFormGroup;

  @Input()
  set companyFormGroup(input: UntypedFormGroup) {
    this._companyFormGroup = input;
  }

  writeValue(value: string): void {
    const { establishmentOwnerId, isEstablishmentOwnerTemporary } = this._companyFormGroup.getRawValue().headquarter;

    // Ajoute le prefix "dt:" pour prendre en compte la domiciliation temporaire et afficher la bonne sélection
    // Le prefix "dt:" est renvoyé par le back lors de la construction des fields => packages/@dougs/core/fields/definitions/HeadquarterRootFields.ts:242
    if (establishmentOwnerId) {
      this._value = isEstablishmentOwnerTemporary ? `${this._tempPrefix}${value}` : String(value);
    } else {
      this._value = value;
    }

    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: number | string) => void): void {
    // Surcharge le "onChange" pour renvoyer la bonne valeur au formControl
    this.onChange = (e) => {
      const isTemp = e.toString().startsWith(this._tempPrefix);
      this._companyFormGroup.get('headquarter.isEstablishmentOwnerTemporary')?.setValue(isTemp);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fn((e !== '' ? Number(e.toString().replace(this._tempPrefix, '')) : null) as any);
    };
  }
}
