import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { ModalService, PanelInfoComponent } from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { EstablishmentsStateService } from '@dougs/settings/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormEstablishmentsService } from '../../../forms/form-establishments.service';
import { AddSecondaryEstablishmentModal } from '../../../modals/add-secondary-establishment-modal/add-secondary-establishment-modal.component';

@Component({
  selector: 'dougs-company-refresh-establishment',
  templateUrl: './company-refresh-establishment.component.html',
  styleUrls: ['./company-refresh-establishment.component.scss'],
  standalone: true,
  imports: [NgIf, PanelInfoComponent, NgClass, AsyncPipe],
})
export class CompanyRefreshEstablishmentComponent {
  hasntFoundNewEstablishments = false;
  isCompanyOnRedList = false;
  isRefreshingEstablishmentFromSiren = false;

  @Input() company!: Company;

  constructor(
    public readonly formEstablishmentsService: FormEstablishmentsService,
    public readonly establishmentsStateService: EstablishmentsStateService,
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    private readonly logger: LoggerService,
    private readonly cdr: ChangeDetectorRef,
    public readonly userStateService: UserStateService,
  ) {}

  async refreshEstablishmentFromSiren(e: Event): Promise<void> {
    e.preventDefault();
    try {
      this.isRefreshingEstablishmentFromSiren = true;
      const establishmentsUpdated = await lastValueFrom(this.establishmentsStateService.establishments$.pipe(take(1)));
      const establishmentsRefreshed = await this.companyStateService.refreshEstablishmentFromSiren(this.company.id);
      this.formEstablishmentsService.formGroup.markAsPristine();
      await this.establishmentsStateService.refreshEstablishments(this.company.id);
      this.isRefreshingEstablishmentFromSiren = false;
      if (establishmentsRefreshed === null) {
        this.isCompanyOnRedList = true;
      }
      this.hasntFoundNewEstablishments =
        !!establishmentsRefreshed && establishmentsUpdated?.length === establishmentsRefreshed.length;
    } catch (e) {
      this.isRefreshingEstablishmentFromSiren = false;
      this.logger.error(e);
    }
    this.cdr.markForCheck();
  }

  async openAddEstablishmentModal(e: Event): Promise<void> {
    e.preventDefault();
    this.hasntFoundNewEstablishments = false;
    await lastValueFrom(
      this.modalService.open(AddSecondaryEstablishmentModal, { data: { companyId: this.company.id } }).afterClosed$,
    );
  }
}
