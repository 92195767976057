import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable, Subject, withLatestFrom } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Company, CompanyMetadata } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { mergeObjects } from '@dougs/core/utils';
import { CheckboxComponent, PanelInfoComponent } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormCompanyService } from '../../../forms/form-company.service';

@Component({
  selector: 'dougs-company-informations',
  templateUrl: './company-informations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CheckboxComponent, FormsModule, PanelInfoComponent, AsyncPipe],
})
export class CompanyInformationsComponent implements OnInit {
  @Input() company!: Company;

  private readonly queueNgModelChange: Subject<Partial<Company>> = new Subject<Partial<Company>>();
  public queueNgModelChange$!: Observable<void>;

  constructor(
    public readonly formCompanyService: FormCompanyService,
    public readonly userStateService: UserStateService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly companyStateService: CompanyStateService,
    private readonly fieldsStateService: FieldsStateService,
  ) {}

  ngOnInit(): void {
    this.queueNgModelChange$ = this.queueNgModelChange.asObservable().pipe(
      concatMap((values) => this.formCompanyService.updateCompany(values)),
      withLatestFrom(this.companyStateService.activeCompany$),
      concatMap(([_, activeCompany]) => this.fieldsStateService.refreshCompanyFields(activeCompany)),
    );
  }

  onUpdateCompanyLocked(locked: boolean): void {
    const updatedCompany: Partial<Company> = mergeObjects(this.company, this.formCompanyService.formGroup.value, {
      locked,
    });
    this.queueNgModelChange.next(updatedCompany);
  }

  onUpdateCompanyPrefilled(prefilled: boolean): void {
    const updatedMetadata: Partial<CompanyMetadata> = mergeObjects(
      this.company.metadata,
      this.formCompanyService.metadataFormGroup?.value,
      {
        prefilled,
      },
    );
    const updatedCompany: Partial<Company> = mergeObjects(this.company, this.formCompanyService.formGroup.value, {
      metadata: updatedMetadata,
    });
    this.queueNgModelChange.next(updatedCompany);
  }
}
